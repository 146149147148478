import React, { useEffect } from 'react';
import OurCommitments from './OurCommitments'
import WhoweareBanner from './WhoweareBanner'
import AboutCompany from './AboutCompany';
 import OurLeadership from './OurLeadership';
//  import Testimonials from './Testimonials';
import Ab from './Ab';
const WhoweAre = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures it only runs once when the component mounts
          // Scroll to the top of the page when the component mounts
 
  return (
    <div>
        <WhoweareBanner/>

        <AboutCompany/>
        {/* <Ab/> */}
        <OurCommitments/>
        <OurLeadership/>
        {/* <OurLeadership/> */}
        {/* { <OurLeadership/> } */}
        {/* { <Testimonials/> } */}
    </div>
  )
}

export default WhoweAre