// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import OwlCarousel from 'react-owl-carousel';

// // Import images
// import img1 from '../img/team/1.png';
// import img2 from '../img/team/2.png';
// import img3 from '../img/team/3.png';
// import img4 from '../img/team/4.png';
// import img5 from '../img/team/5.png';

// const OurLeadership = () => {
//   const teamMembers = [
//     {
//       name: 'C Chandra Sekhar Reddy',
//       jobTitle: 'CEO & Founder',
//       imgSrc: img1,
//     },
//     {
//       name: 'Asha Jyothi G',
//       jobTitle: 'Chief Client Relations Officer',
//       imgSrc: img2,
//     },
//     {
//       name: 'Kiran Kumar M',
//       jobTitle: 'Marketing Manager',
//       imgSrc: img3,
//     },
//     {
//       name: 'Eswar Raju K',
//       jobTitle: 'SA Manager',
//       imgSrc: img4,
//     },
//     {
//       name: 'Priti Pragyan B',
//       jobTitle: 'ITE Manager',
//       imgSrc: img5,
//     },
//   ];

//   return (
//     <section className="no-bottom" aria-label="team">
//       <div className="container-fluid">
//         <div className='container'>
//         <div className=" row" style={{ paddingBottom: '30px' }}>
//         <div className="container">
//               <div className="section-title fl-wrap" style={{ textAlign: 'center',marginBottom:"-30px" }}>
//                 <h2 >Our LEADERSHIP</h2>
//               </div>
//             </div>

//           <div className="col-12 centered ">
//             <div className="detailcontent text-center p-0">
//               {/* <div className="subheading">OUR PROFESSIONALS</div> */}
//               {/* <div className="heading">Meet Our Team</div> */}
//             </div>
//           </div>
//           <div className="col-12 p-0">
//             <OwlCarousel className="owl-theme" loop margin={10} nav>
//               {teamMembers.map((member, index) => (
//                 <div className="item" key={index}>
//                   <div className="listteam">
//                     <div className="bgimg">
//                       <img src={member.imgSrc} alt={member.name} />
//                     </div>
//                     <div className="detail">
//                       <div className="listdetail">
//                         <div className="name">{member.name}</div>
//                         <div className="jobtitle">{member.jobTitle}</div>
//                         <div className="s-social">
//                           <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
//                           <a href="#"><FontAwesomeIcon icon={faFacebook} /></a>
//                           <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
//                           <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </OwlCarousel>
//           </div>
//         </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default OurLeadership;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

// Import images
import img1 from '../img/team/1.png';
import img2 from '../img/team/2.png';
import img3 from '../img/team/3.png';
import img4 from '../img/team/4.png';
import img5 from '../img/team/5.png';

const OurLeadership = () => {
  const teamMembers = [
    { name: 'C Chandra Sekhar Reddy', jobTitle: 'CEO & Founder', imgSrc: img1 },
    { name: 'Asha Jyothi G', jobTitle: 'Chief Client Relations Officer', imgSrc: img2 },
    { name: 'Kiran Kumar M', jobTitle: 'Marketing Manager', imgSrc: img3 },
    { name: 'Eswar Raju K', jobTitle: 'SA Manager', imgSrc: img4 },
    { name: 'Priti Pragyan B', jobTitle: 'ITE Manager', imgSrc: img5 },
  ];

  const sectionStyle = {
    padding: '50px 0',
    backgroundColor: '#f8f9fa',
  };

  const headingStyle = {
    fontSize: '48px',
    fontWeight: '700',
    color: '#10100e',
    textAlign: 'center',
    marginBottom: '30px',
  };

  const headingHighlightStyle = {
    color: '#0a8194',
    fontFamily: 'lato, sans-serif',
  };

  const flipCardStyle = {
    perspective: '1000px', // Enables 3D effect
    width: '100%',
    height: '400px', // Increased height for better image visibility
    marginBottom: '20px',
  };

  const flipCardInnerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
  };

  const flipCardFrontStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    background: '#fff',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.5)',
  };

  const flipCardBackStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    background: '#0a8194',
    color: '#fff',
    transform: 'rotateY(180deg)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    textAlign: 'center',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.5)',
  };

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    responsive: {
      0: {
        items: 1, // Show 1 card on mobile
      },
      600: {
        items: 2, // Show 2 cards on tablets
      },
      1000: {
        items: 3, // Show 3 cards on desktops
      },
    },
  };

  return (
    <section style={sectionStyle}>
      <div className="container">
      <div style={{ textAlign: 'center', marginBottom: '30px',fontFamily:'lato,sans,serif',fontSize:'48px', }}>
  <h2 style={headingStyle}>
    Our <span style={headingHighlightStyle}>Leadership</span>
  </h2>
  <div
    style={{
      width: '200px', // Adjust the width of the line
      height: '2px', // Thickness of the line
      backgroundColor: '#000', // Black color
      margin: '10px auto 0', // Center alignment and spacing
    }}
  ></div>
</div>

        <OwlCarousel className="owl-theme" {...carouselOptions}>
          {teamMembers.map((member, index) => (
            <div className="item" key={index}>
              <div
                className="flip-card"
                style={flipCardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.firstChild.style.transform = 'rotateY(180deg)')
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.firstChild.style.transform = 'rotateY(0deg)')
                }
              >
                <div className="flip-card-inner" style={flipCardInnerStyle}>
                  {/* Front Side */}
                  <div className="flip-card-front" style={flipCardFrontStyle}>
                    <img
                      src={member.imgSrc}
                      alt={member.name}
                      style={{
                        width: '100%',
                        height: '250px',
                        objectFit: 'cover',
                        borderRadius: '10px',
                      }}
                    />
                    <div style={{ padding: '10px', textAlign: 'center' }}>
                      <h3>{member.name}</h3>
                      <p>{member.jobTitle}</p>
                    </div>
                  </div>

                  {/* Back Side */}
                  <div className="flip-card-back" style={flipCardBackStyle}>
                    <h3>{member.name}</h3>
                    <p>{member.jobTitle}</p>
                    <div style={{ marginTop: '15px' }}>
                      <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                      <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                      <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                      <a href="#" style={{ margin: '0 10px', color: '#fff' }}>
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default OurLeadership;
