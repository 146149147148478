




import React, { useEffect, useState } from 'react';
import bannerImage from '../img/aaaservices/BAIINER.jpg';
import abouthome6 from '../img/aaaservices/BAIINER.jpg';
import abouthome from '../img/banner/HERO3.jpg';
import abouthome3 from '../img/aaaservices/MINI3.jpg';
import abouthome1 from '../img/banner/TRUCK.jpg'
import abouthome5 from '../img/aaaservices/MIDUAM BI.jpg'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import galleryImg1 from '../img/banner/bajaj3.png';
import galleryImg2 from '../img/banner/HERO3.jpg';
import galleryImg3 from '../img/banner/himlya7.avif';
import galleryImg4 from '../img/banner/HERO7.jpeg';
import galleryImg5 from '../img/banner/TVS7.jpg';
import galleryImg6 from '../img/banner/HERO8.webp';
import galleryImg7 from '../img/banner/HERO7.jpeg';
import galleryImg8 from '../img/banner/bajaj7.webp';





import galleryImg9 from '../img/aaaservices/MINI6.jpg';
import galleryImg10 from '../img/aaaservices/MINI5.jpeg';
import galleryImg11 from '../img/aaaservices/MINI2.webp';
import galleryImg12 from '../img/aaaservices/MINI1.webp';
import galleryImg13 from '../img/aaaservices/MINI3.jpg';
import galleryImg14 from '../img/aaaservices/motor2.jpg';
import galleryImg15 from '../img/aaaservices/MOTOR1.jpg';
import galleryImg16 from '../img/aaaservices/EICHER5.webp';


import galleryImg17 from '../img/aaaservices/TRUCK11.avif';
import galleryImg18 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg19 from '../img/aaaservices/TRUCK3.avif';
import galleryImg20 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg21 from '../img/aaaservices/TRUCK5.jpg';
import galleryImg22 from '../img/aaaservices/TRUCK6.jpg';
import galleryImg23 from '../img/aaaservices/TRUCK7.jpg';
import galleryImg24 from '../img/aaaservices/TRUCK8.webp';




import galleryImg25 from '../img/aaaservices/MIDIUM1.png';
import galleryImg26 from '../img/aaaservices/MIDUAM2.webp';
import galleryImg27 from '../img/aaaservices/MIDUAM3.JPG';
import galleryImg28 from '../img/aaaservices/MIDUAM5.webp';
import galleryImg29 from '../img/aaaservices/MIDUAM BI.jpg';
import galleryImg30 from '../img/aaaservices/MIDUAM7.jpg';
import galleryImg31 from '../img/aaaservices/EICHER5.webp';
import galleryImg32 from '../img/aaaservices/MOTOR1.jpg';


import galleryImg33 from '../img/aaaservices/TRUCK11.avif';
import galleryImg34 from '../img/aaaservices/MINI2.webp'
import galleryImg35 from '../img/aaaservices/TRUCK11.avif';
import galleryImg36 from '../img/aaaservices/TRUCK5.jpg';
import galleryImg37 from '../img/aaaservices/MIDUAM BI.jpg';
import galleryImg38 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg39 from '../img/aaaservices/MINI3.jpg';
import galleryImg40 from '../img/aaaservices/MINI5.jpeg';




import ReactImageLightbox from 'react-image-lightbox';
const WhatWeDo = () => {

  const [navbarHeight, setNavbarHeight] = useState(93);
  const [selectedIcon, setSelectedIcon] = useState('RESIDENTIAL_INTERIORS'); // Default selected content
  const [activeFilter, setActiveFilter] = useState('all');

  useEffect(() => {
    window.scrollTo(0, 0);
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    time: '',
    vanQuantity: '',
    zipLocation: '',
    destination: '',
    message: '',
    distance: 0,
    amount: 0,
  });

  const openModal = (imgSrc, alt) => {
    setCurrentImage({ imgSrc, alt });
    setIsOpen(true);
  };

  const closeModal = () => {
    setCurrentImage(null);
    setIsOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission (trigger second modal)
  const handleSubmit = (e) => {
    e.preventDefault();
    // Show second modal after submitting the form
    setIsOpen(false); // Close the first modal
    setIsSecondModalOpen(true); // Open the second modal
  };

  // Close the second modal
  const closeSecondModal = () => {
    setIsSecondModalOpen(false); // Close the modal
  };


  const handleLocationChange = () => {
    const { zipLocation, destination } = formData;

    if (zipLocation && destination) {
      // Simulate a distance calculation (replace with actual logic or API)
      const distance = calculateDistance(zipLocation, destination);
      const amount = distance * 10; // Example: $10 per kilometer

      setFormData((prevData) => ({
        ...prevData,
        distance,
        amount,
      }));
    }
  };
  const calculateDistance = (zipLocation, destination) => {
    // Replace this with actual logic for calculating distance
    // This is a placeholder, assuming the distance is calculated based on zipLocation and destination
    return Math.floor(Math.random() * 100) + 1; // Random value between 1 and 100 km
  };


  const styles = {
    galleryArea: {
      padding: "20px",
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      justifyContent: "center",
    },
    imageContainer: {
      cursor: "pointer",
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "350px",
      borderRadius: "5px",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      position: "relative",
      background: "#fff",
      padding: "20px",
      borderRadius: "8px",
      maxWidth: "80%", // Increase the width of the modal
      maxHeight: "80%",
      display: "flex", // Make the modal a flex container
      flexDirection: "row", // Display items side by side
      justifyContent: "space-between", // Space between image and form
      alignItems: "flex-start", // Align image and form at the top
      width: '70%',
    },
    modalImage: {
      width: "45%", // Adjust image width to 45% so there is space for the form
      height: "auto",
      objectFit: "contain",
      borderRadius: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "-3px",
      right: "10px",
      background: "transparent",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
      color: "#000",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "45%", // Adjust form width to 45% to fit alongside the image
    },
    input: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      padding: "10px",
      fontSize: "16px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };




  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  const renderContent = () => {
    switch (selectedIcon) {
      case 'THE_DESIGN':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Raqupine Regular',
                        display: 'inline-block',
                        borderBottom: '2px solid black',
                        paddingBottom: '2px',
                        fontSize: '25px',
                        color: 'grey',
                      }}
                    > MIDIUAM VEHICLES
                    </span>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span>The Interiors transforms your space with cutting-edge virtual reality technology, allowing you to experience your dream interior before it’s built. We blend innovation with creativity, offering immersive design solutions that bring your ideas to life. Our team works closely with you to craft interiors that are both functional and aesthetically pleasing.
                      .
                    </p>
                  </div>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                  </p>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> With MODULEX Interiors, you can explore and refine every detail of your design in a fully interactive 3D environment. Step into the future of interior design and watch your vision come alive like never before. </p>

                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg1, "Co-working space")}
                >
                  <img src={galleryImg1} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg2, "Virtual Office")}
                >
                  <img src={galleryImg2} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg3, "Dedicated Desk")}
                >
                  <img src={galleryImg3} alt="Dedicated Desk" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg4, "Private Office")}
                >
                  <img src={galleryImg4} alt="Private Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg5, "Meeting Spaces")}
                >
                  <img src={galleryImg5} alt="Meeting Spaces" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg6, "Co-working space")}
                >
                  <img src={galleryImg6} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg7, "Virtual Office")}
                >
                  <img src={galleryImg7} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg8, "Dedicated Desk")}
                >
                  <img src={galleryImg8} alt="Dedicated Desk" style={styles.image} />
                </div>
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={styles.modalContent}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleFormChange}
                          rows="4"
                          style={styles.input}
                        />
                        <button type="submit" style={styles.button}>
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

      case 'COMMERCIAL_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome3} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      MINI <span style={{ color: "#3395AD" }}>VEHICLES</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> interiors, we specialize in creating functional and aesthetically pleasing commercial interiors that enhance productivity and reflect your brand’s identity. Our expertise spans office spaces, retail stores, restaurants, and more, with designs that prioritize both efficiency and visual appeal. We integrate advanced technology, ergonomic furniture, and innovative layouts to craft environments where employees thrive and customers feel welcome.
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> Whether it’s a modern office with flexible workstations or a chic retail space with engaging displays, our tailored solutions ensure your commercial interiors are both practical and inspiring, perfectly aligned with your business goals </p>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg9, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg9}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg10, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg10}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg11, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg11}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg12, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg12}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg13, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg13}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg14, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg14}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg15, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg15}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg16, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg16}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,

                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      backgroundColor: 'grey',
                      transform: 'translateX(-50%)',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={{
                    ...styles.modalContent,
                    maxHeight: '80vh', // Add a maximum height for the modal content
                    overflowY: 'auto', // Enable vertical scrolling when content overflows
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img className='image-continent'
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <div style={{ textAlign: 'center' }}>
                          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h2 style={{ color: "#000", fontSize: '30px', display: 'inline-block', position: 'relative' }} className='truck-specification'>
                              TRUCK <span style={{ color: "#3395AD" }}> SPECIFICATIONS</span>
                            </h2>
                            <hr style={{
                              width: '50%',  // Adjust the width as needed
                              border: 'none',
                              borderTop: '2px solid #999',  // Line color
                              margin: '0 auto',

                            }}
                              className='horizt-line'
                            />
                          </div>
                          <ol style={{ listStyleType: 'decimal', paddingLeft: '20px', fontSize: '18px', lineHeight: '1.5', textAlign: 'left', display: 'inline-block' }}>
                            <ol style={{ fontSize: '18px', lineHeight: '1.8', listStyleType: 'decimal', paddingLeft: '20px' }} className='ol-data-content'>
                              <li style={{ marginBottom: '10px' }}> Gross Vehicle Weight Rating (GVWR): 10,000 lbs to 26,000 lbs (4500 kg to 11,800 kg)</li>
                              <li style={{ marginBottom: '10px' }}> Fuel Efficiency: 8 km/l to 12 km/l (varies depending on engine size and load)</li>
                              <li style={{ marginBottom: '10px' }}> Engine Type: Inline 4 or 6-cylinder diesel or gasoline engine</li>
                              <li style={{ marginBottom: '10px' }}> Power Output: 150 hp to 300 hp</li>
                              <li style={{ marginBottom: '10px' }}> Torque: 400 Nm to 800 Nm</li>
                              <li style={{ marginBottom: '10px' }}> Length: 6.5m to 9.0m (varies by model)</li>
                              <li style={{ marginBottom: '10px' }}> Payload Capacity: 6,000 lbs to 18,000 lbs (2,700 kg to 8,200 kg)</li>
                              <li style={{ marginBottom: '10px' }}> Engine Options: 6.4L HEMI V8, 6.7L Cummins diesel</li>
                            </ol>

                          </ol>
                        </div>
                        <button type="submit" style={{ ...styles.button, backgroundColor: '#6C757D' }} className='booknow-button-data'>
                          Book Now
                        </button>

                      </form>
                    </>
                  )}
                </div>
              </div>
            )}

            {/* Second Modal (Confirmation Modal) */}
            {isSecondModalOpen && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
                onClick={closeSecondModal}
              >
                <div
                  style={{
                    width: '526px', // Adjust width if needed
                    minHeight: '665px', // Minimum height
                    maxHeight: '90vh', // Limit height to viewport
                    overflowY: 'auto', // Add vertical scroll bar if needed
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <h2 style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
                    Enquiry Form
                  </h2>

                  <p>Fill in your details and we’ll get back to you shortly.</p>

                  <button
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      fontSize: '20px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={closeSecondModal}
                  >
                    &times;
                  </button>

                  <form
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                    }}
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="time"
                      name="time"
                      value={formData.time}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <select
                      name="vanQuantity"
                      value={formData.vanQuantity}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    >
                      <option value="">Select Van Quantity</option>
                      <option value="1">1 Van</option>
                      <option value="2">2 Vans</option>
                      <option value="3">3 Vans</option>
                      <option value="4">4 Vans</option>
                      <option value="5">5 Vans</option>
                    </select>

                    <input
                      type="text"
                      name="zipLocation"
                      placeholder="Zip Location"
                      value={formData.zipLocation}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="text"
                      name="destination"
                      placeholder="Destination"
                      value={formData.destination}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <div style={{ marginBottom: '10px' }}>
                      <textarea
                        placeholder="Your Message"
                        rows="4"
                        style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      style={{
                        width: '100%',
                        padding: '12px',
                        backgroundColor: '#495057',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        textAlign: 'center',
                      }}
                    >
                      Book Now
                    </button>
                  </form>
                </div>
              </div>
            )}


          </div>
        </div>
      case 'SHOWCASE_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome5} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      MEDIUM <span style={{ color: "#3395AD" }}> VEHICLES</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> Our Hospitality Interiors are designed to create unforgettable experiences for guests, blending luxury and comfort with functional elegance. We specialize in crafting spaces that cater to the unique needs of hotels, resorts, and restaurants, ensuring each area—from lobbies to guest rooms—exudes warmth and sophistication..
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES</span> Our designs incorporate cutting-edge technology, high-quality materials, and thoughtful layouts to enhance both aesthetics and efficiency. Whether it's a serene spa, a stylish dining area, or a welcoming reception, we focus on creating environments that leave a lasting impression, making every stay or visit a memorable one. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg25, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg25}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg26, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg26}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg27, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg27}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg28, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg28}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg29, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg29}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg30, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg30}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg31, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg31}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg32, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg32}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,

                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      backgroundColor: 'grey',
                      transform: 'translateX(-50%)',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={{
                    ...styles.modalContent,
                    maxHeight: '80vh', // Add a maximum height for the modal content
                    overflowY: 'auto', // Enable vertical scrolling when content overflows
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img className='image-continent'
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <div style={{ textAlign: 'center' }}>
                          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h2 className='truck-specification' style={{ color: "#000", fontSize: '30px', display: 'inline-block', position: 'relative' }}>
                              TRUCK <span style={{ color: "#3395AD" }}> SPECIFICATIONS</span>
                            </h2>
                            <hr style={{
                              width: '50%',  // Adjust the width as needed
                              border: 'none',
                              borderTop: '2px solid #999',  // Line color
                              margin: '0 auto',

                            }}
                              className='horizt-line'
                            />
                          </div>
                          <ol style={{ listStyleType: 'decimal', paddingLeft: '20px', fontSize: '18px', lineHeight: '1.5', textAlign: 'left', display: 'inline-block' }}>
                            <ol className='ol-data-content' style={{ fontSize: '18px', lineHeight: '1.8', listStyleType: 'decimal', paddingLeft: '20px' }}>
                              <li style={{ marginBottom: '10px' }}> Gross Vehicle Weight Rating (GVWR): 10,000 lbs to 26,000 lbs (4500 kg to 11,800 kg)</li>
                              <li style={{ marginBottom: '10px' }}> Fuel Efficiency: 8 km/l to 12 km/l (varies depending on engine size and load)</li>
                              <li style={{ marginBottom: '10px' }}> Engine Type: Inline 4 or 6-cylinder diesel or gasoline engine</li>
                              <li style={{ marginBottom: '10px' }}> Power Output: 150 hp to 300 hp</li>
                              <li style={{ marginBottom: '10px' }}> Torque: 400 Nm to 800 Nm</li>
                              <li style={{ marginBottom: '10px' }}> Length: 6.5m to 9.0m (varies by model)</li>
                              <li style={{ marginBottom: '10px' }}> Payload Capacity: 6,000 lbs to 18,000 lbs (2,700 kg to 8,200 kg)</li>
                              <li style={{ marginBottom: '10px' }}> Engine Options: 6.4L HEMI V8, 6.7L Cummins diesel</li>
                            </ol>

                          </ol>
                        </div>


                        <button className='booknow-button-data' type="submit" style={{ ...styles.button, backgroundColor: '#6C757D' }}>
                          Book Now
                        </button>

                      </form>
                    </>
                  )}
                </div>
              </div>
            )}

            {/* Second Modal (Confirmation Modal) */}
            {isSecondModalOpen && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
                onClick={closeSecondModal}
              >
                <div
                  style={{
                    width: '526px', // Adjust width if needed
                    minHeight: '665px', // Minimum height
                    maxHeight: '90vh', // Limit height to viewport
                    overflowY: 'auto', // Add vertical scroll bar if needed
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <h2 style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
                    Enquiry Form
                  </h2>

                  <p>Fill in your details and we’ll get back to you shortly.</p>

                  <button
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      fontSize: '20px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={closeSecondModal}
                  >
                    &times;
                  </button>

                  <form
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                    }}
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="time"
                      name="time"
                      value={formData.time}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <select
                      name="vanQuantity"
                      value={formData.vanQuantity}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    >
                      <option value="">Select Van Quantity</option>
                      <option value="1">1 Van</option>
                      <option value="2">2 Vans</option>
                      <option value="3">3 Vans</option>
                      <option value="4">4 Vans</option>
                      <option value="5">5 Vans</option>
                    </select>

                    <input
                      type="text"
                      name="zipLocation"
                      placeholder="Zip Location"
                      value={formData.zipLocation}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="text"
                      name="destination"
                      placeholder="Destination"
                      value={formData.destination}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <div style={{ marginBottom: '10px' }}>
                      <textarea
                        placeholder="Your Message"
                        rows="4"
                        style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      style={{
                        width: '100%',
                        padding: '12px',
                        backgroundColor: '#495057',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        textAlign: 'center',
                      }}
                    >
                      Book Now
                    </button>
                  </form>
                </div>
              </div>
            )}


          </div>
        </div>

      case 'HOSPITALITY_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome1} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      lARGE <span style={{ color: "#3395AD" }}> VEHICLES</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> Our Hospitality Interiors are designed to create unforgettable experiences for guests, blending luxury and comfort with functional elegance. We specialize in crafting spaces that cater to the unique needs of hotels, resorts, and restaurants, ensuring each area—from lobbies to guest rooms—exudes warmth and sophistication..
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES</span> Our designs incorporate cutting-edge technology, high-quality materials, and thoughtful layouts to enhance both aesthetics and efficiency. Whether it's a serene spa, a stylish dining area, or a welcoming reception, we focus on creating environments that leave a lasting impression, making every stay or visit a memorable one. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg17, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg17}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg18, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg18}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg19, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg19}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg20, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg20}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg21, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg21}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg22, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg22}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg23, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg23}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg24, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg24}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,

                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      backgroundColor: 'grey',
                      transform: 'translateX(-50%)',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>

                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={{
                    ...styles.modalContent,
                    maxHeight: '80vh', // Add a maximum height for the modal content
                    overflowY: 'auto', // Enable vertical scrolling when content overflows
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img
                        className='image-continent'
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <div style={{ textAlign: 'center' }}>
                          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h2 style={{ color: "#000", fontSize: '30px', display: 'inline-block', position: 'relative' }} className='truck-specification'>
                              TRUCK <span style={{ color: "#3395AD" }}> SPECIFICATIONS</span>
                            </h2>
                            <hr style={{
                              width: '50%',  // Adjust the width as needed
                              border: 'none',
                              borderTop: '2px solid #999',  // Line color
                              margin: '0 auto'
                            }}
                              className='horizt-line'

                            />
                          </div>
                          <ol style={{ listStyleType: 'decimal', paddingLeft: '20px', fontSize: '18px', lineHeight: '1.5', textAlign: 'left', display: 'inline-block' }}>
                            <ol style={{ fontSize: '18px', lineHeight: '1.8', listStyleType: 'decimal', paddingLeft: '20px' }} className='ol-data-content'>
                              <li style={{ marginBottom: '10px' }}> Gross Vehicle Weight Rating (GVWR): 10,000 lbs to 26,000 lbs (4500 kg to 11,800 kg)</li>
                              <li style={{ marginBottom: '10px' }}> Fuel Efficiency: 8 km/l to 12 km/l (varies depending on engine size and load)</li>
                              <li style={{ marginBottom: '10px' }}> Engine Type: Inline 4 or 6-cylinder diesel or gasoline engine</li>
                              <li style={{ marginBottom: '10px' }}> Power Output: 150 hp to 300 hp</li>
                              <li style={{ marginBottom: '10px' }}> Torque: 400 Nm to 800 Nm</li>
                              <li style={{ marginBottom: '10px' }}> Length: 6.5m to 9.0m (varies by model)</li>
                              <li style={{ marginBottom: '10px' }}> Payload Capacity: 6,000 lbs to 18,000 lbs (2,700 kg to 8,200 kg)</li>
                              <li style={{ marginBottom: '10px' }}> Engine Options: 6.4L HEMI V8, 6.7L Cummins diesel</li>
                            </ol>

                          </ol>
                        </div>

                        <button type="submit" style={{ ...styles.button, backgroundColor: '#6C757D' }} className='booknow-button-data'>
                          Book Now
                        </button>


                      </form>
                    </>
                  )}
                </div>
              </div>
            )}

            {isSecondModalOpen && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
                onClick={closeSecondModal}
              >
                <div
                  style={{
                    width: '526px', // Adjust width if needed
                    minHeight: '665px', // Minimum height
                    maxHeight: '90vh', // Limit height to viewport
                    overflowY: 'auto', // Add vertical scroll bar if needed
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <h2 style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
                    Enquiry Form
                  </h2>

                  <p>Fill in your details and we’ll get back to you shortly.</p>

                  <button
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      fontSize: '20px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={closeSecondModal}
                  >
                    &times;
                  </button>

                  <form
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                    }}
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="time"
                      name="time"
                      value={formData.time}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <select
                      name="vanQuantity"
                      value={formData.vanQuantity}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    >
                      <option value="">Select Van Quantity</option>
                      <option value="1">1 Van</option>
                      <option value="2">2 Vans</option>
                      <option value="3">3 Vans</option>
                      <option value="4">4 Vans</option>
                      <option value="5">5 Vans</option>
                    </select>

                    <input
                      type="text"
                      name="zipLocation"
                      placeholder="Zip Location"
                      value={formData.zipLocation}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="text"
                      name="destination"
                      placeholder="Destination"
                      value={formData.destination}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <div style={{ marginBottom: '10px' }}>
                      <textarea
                        placeholder="Your Message"
                        rows="4"
                        style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      style={{
                        width: '100%',
                        padding: '12px',
                        backgroundColor: '#495057',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        textAlign: 'center',
                      }}
                    >
                      Book Now
                    </button>
                  </form>
                </div>
              </div>
            )}


          </div>
        </div>


      case 'SPECIALTY_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      SPECIALTY <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> Specialty Interiors focus on creating unique spaces tailored to specific needs and interests. Whether designing a state-of-the-art Home Theater with immersive audio-visual experiences, a dynamic Game Room with cutting-edge gaming setups, or a fully automated Smart Home integrating the latest technology for convenience and efficiency,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> our designs are crafted to enhance every aspect of your lifestyle. We also specialize in Custom Workspaces that blend functionality with style to boost productivity. Each project is meticulously planned to reflect your personal preferences while ensuring maximum comfort and innovation </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg1, "Co-working space")}
                >
                  <img src={galleryImg1} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg2, "Virtual Office")}
                >
                  <img src={galleryImg2} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg3, "Dedicated Desk")}
                >
                  <img src={galleryImg3} alt="Dedicated Desk" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg4, "Private Office")}
                >
                  <img src={galleryImg4} alt="Private Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg5, "Meeting Spaces")}
                >
                  <img src={galleryImg5} alt="Meeting Spaces" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg6, "Co-working space")}
                >
                  <img src={galleryImg6} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg7, "Virtual Office")}
                >
                  <img src={galleryImg7} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg8, "Dedicated Desk")}
                >
                  <img src={galleryImg8} alt="Dedicated Desk" style={styles.image} />
                </div>
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={styles.modalContent}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleFormChange}
                          rows="4"
                          style={styles.input}
                        />
                        <button type="submit" style={styles.button}>
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

      case 'OUTDOOR_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      OUTDOOR <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MODULEX INTERIORS</span> Outdoor and Landscape Interiors transform exterior spaces into extensions of your living area. Our designs include Stylish Patios with comfortable seating and durable materials for relaxation and entertaining, Elegant Pool Areas featuring luxurious pools, lounging spaces, and outdoor kitchens, and Beautiful Garden Spaces with thoughtfully designed landscaping, pathways, and lighting to create serene environments. .,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> We integrate Custom Features like built-in fire pits, outdoor fireplaces, and pergolas to enhance functionality and aesthetics. Each project is tailored to complement your home's style while maximizing outdoor enjoyment and usability. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg1, "Co-working space")}
                >
                  <img src={galleryImg1} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg2, "Virtual Office")}
                >
                  <img src={galleryImg2} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg3, "Dedicated Desk")}
                >
                  <img src={galleryImg3} alt="Dedicated Desk" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg4, "Private Office")}
                >
                  <img src={galleryImg4} alt="Private Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg5, "Meeting Spaces")}
                >
                  <img src={galleryImg5} alt="Meeting Spaces" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg6, "Co-working space")}
                >
                  <img src={galleryImg6} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg7, "Virtual Office")}
                >
                  <img src={galleryImg7} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg8, "Dedicated Desk")}
                >
                  <img src={galleryImg8} alt="Dedicated Desk" style={styles.image} />
                </div>
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={styles.modalContent}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleFormChange}
                          rows="4"
                          style={styles.input}
                        />
                        <button type="submit" style={styles.button}>
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

      case 'RESIDENTAL_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      THE <span style={{ color: "#3395AD" }}>BIKES</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>MO</span> Outdoor and Landscape Interiors transform exterior spaces into extensions of your living area. Our designs include Stylish Patios with comfortable seating and durable materials for relaxation and entertaining, Elegant Pool Areas featuring luxurious pools, lounging spaces, and outdoor kitchens, and Beautiful Garden Spaces with thoughtfully designed landscaping, pathways, and lighting to create serene environments. .,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> MODULEX </span> We integrate Custom Features like built-in fire pits, outdoor fireplaces, and pergolas to enhance functionality and aesthetics. Each project is tailored to complement your home's style while maximizing outdoor enjoyment and usability. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg1, "Co-working space")}
                >
                  <img src={galleryImg1} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg2, "Virtual Office")}
                >
                  <img src={galleryImg2} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg3, "Dedicated Desk")}
                >
                  <img src={galleryImg3} alt="Dedicated Desk" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg4, "Private Office")}
                >
                  <img src={galleryImg4} alt="Private Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg5, "Meeting Spaces")}
                >
                  <img src={galleryImg5} alt="Meeting Spaces" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg6, "Co-working space")}
                >
                  <img src={galleryImg6} alt="Co-working space" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg7, "Virtual Office")}
                >
                  <img src={galleryImg7} alt="Virtual Office" style={styles.image} />
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg8, "Dedicated Desk")}
                >
                  <img src={galleryImg8} alt="Dedicated Desk" style={styles.image} />
                </div>
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={styles.modalContent}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleFormChange}
                          style={styles.input}
                        />
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleFormChange}
                          rows="4"
                          style={styles.input}
                        />
                        <button type="submit" style={styles.button}>
                          Submit
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>


      default:
        return <div><section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome6} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Raqupine Regular',
                        display: 'inline-block',
                        borderBottom: '2px solid black',
                        paddingBottom: '2px',
                        fontSize: '25px',
                        color: 'grey',
                      }}
                    > THE VEHICLES
                    </span>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span>The Interiors transforms your space with cutting-edge virtual reality technology, allowing you to experience your dream interior before it’s built. We blend innovation with creativity, offering immersive design solutions that bring your ideas to life. Our team works closely with you to craft interiors that are both functional and aesthetically pleasing. .,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> With MODULEX Interiors, you can explore and refine every detail of your design in a fully interactive 3D environment. Step into the future of interior design and watch your vision come alive like never before. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}
            <div className="container-fluid">
              <div className="row">
                {/* Adjusting columns for larger images */}
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg33, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg33}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg34, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg34}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg35, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg35}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg36, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg36}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg37, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg37}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg38, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg38}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg39, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg39}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,
                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      transform: 'translateX(-50%)',
                      backgroundColor: 'grey',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                <div
                  className="col-md-3 col-sm-6 col-12"
                  style={{
                    ...styles.imageContainer,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',

                    position: 'relative',
                  }}
                  onClick={() => openModal(galleryImg40, "Dedicated Desk")}
                >
                  <img
                    src={galleryImg40}
                    alt="Dedicated Desk"
                    style={{ ...styles.image, }}
                  />
                  <button
                    style={{
                      ...styles.button,

                      position: 'absolute',
                      bottom: '10px', // Adjust based on where you want it
                      textAlign: 'center',
                      backgroundColor: 'grey',
                      transform: 'translateX(-50%)',
                      left: '50%',
                    }}
                  >
                    Know More
                  </button>
                </div>
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg9, "Private Office")}
                >
                  <img src={galleryImg9} alt="Private Office" style={styles.image} />
                </div> */}
                {/* <div
                  className="col-md-3 col-sm-6 col-12"
                  style={styles.imageContainer}
                  onClick={() => openModal(galleryImg10, "Meeting Spaces")}
                >
                  <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
                </div> */}
              </div>
            </div>

            {/* Modal */}
            {isOpen && (
              <div style={styles.modalOverlay} onClick={closeModal}>
                <div
                  style={{
                    ...styles.modalContent,
                    maxHeight: '80vh', // Add a maximum height for the modal content
                    overflowY: 'auto', // Enable vertical scrolling when content overflows
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button style={styles.closeButton} onClick={closeModal}>
                    &times;
                  </button>
                  {currentImage && (
                    <>
                      <img className='image-continent'
                        src={currentImage.imgSrc}
                        alt={currentImage.alt}
                        style={styles.modalImage}
                      />
                      <form style={styles.form} onSubmit={handleSubmit}>
                        <div style={{ textAlign: 'center' }}>
                          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <h2 className='truck-specification'
                              style={{
                                color: "#000",
                                fontSize: window.innerWidth < 768 ? '' : '', // 20px for mobile screens and 6vw for larger screens
                                display: 'inline-block',
                                position: 'relative',
                                lineHeight: '1.2', // Adjust line height for readability
                              }}
                            >
                              TRUCK <span style={{ color: "#3395AD" }}> SPECIFICATIONS</span>
                            </h2>

                            <hr
                              style={{
                                border: 'none',
                                borderTop: '2px solid #999',
                                margin: '0 auto',
                              }}
                              className='horizt-line'
                            />
                          </div>
                          <ol
                            style={{
                              listStyleType: 'decimal',
                              paddingLeft: '20px',
                              fontSize: '18px',
                              lineHeight: '1.5',
                              textAlign: 'left',
                              display: 'inline-block',
                            }}
                          >
                            <ol className='ol-data-content' style={{ fontSize: '18px', lineHeight: '1.8', listStyleType: 'decimal', paddingLeft: '20px' }}>
                              <li style={{ marginBottom: '10px' }}>
                                Gross Vehicle Weight Rating (GVWR): 10,000 lbs to 26,000 lbs (4500 kg to 11,800 kg)
                              </li>
                              <li style={{ marginBottom: '10px' }}>
                                Fuel Efficiency: 8 km/l to 12 km/l (varies depending on engine size and load)
                              </li>
                              <li style={{ marginBottom: '10px' }}>
                                Engine Type: Inline 4 or 6-cylinder diesel or gasoline engine
                              </li>
                              <li style={{ marginBottom: '10px' }}>Power Output: 150 hp to 300 hp</li>
                              <li style={{ marginBottom: '10px' }}>Torque: 400 Nm to 800 Nm</li>
                              <li style={{ marginBottom: '10px' }}>Length: 6.5m to 9.0m (varies by model)</li>
                              <li style={{ marginBottom: '10px' }}>
                                Payload Capacity: 6,000 lbs to 18,000 lbs (2,700 kg to 8,200 kg)
                              </li>
                              <li style={{ marginBottom: '10px' }}>Engine Options: 6.4L HEMI V8, 6.7L Cummins diesel</li>
                            </ol>
                          </ol>
                        </div>

                        <button type="submit" style={{ ...styles.button, backgroundColor: '#6C757D' }} className='booknow-button-data'>
                          Book Now
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </div>
            )}


            {/* Second Modal (Confirmation Modal) */}
            {isSecondModalOpen && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000,
                }}
                onClick={closeSecondModal}
              >
                <div
                  style={{
                    width: '526px', // Adjust width if needed
                    minHeight: '665px', // Minimum height
                    maxHeight: '90vh', // Limit height to viewport
                    overflowY: 'auto', // Add vertical scroll bar if needed
                    padding: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <h2 style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
                    Enquiry Form
                  </h2>

                  <p>Fill in your details and we’ll get back to you shortly.</p>

                  <button
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      fontSize: '20px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={closeSecondModal}
                  >
                    &times;
                  </button>

                  <form
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                    }}
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="time"
                      name="time"
                      value={formData.time}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <select
                      name="vanQuantity"
                      value={formData.vanQuantity}
                      onChange={handleFormChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    >
                      <option value="">Select Van Quantity</option>
                      <option value="1">1 Van</option>
                      <option value="2">2 Vans</option>
                      <option value="3">3 Vans</option>
                      <option value="4">4 Vans</option>
                      <option value="5">5 Vans</option>
                    </select>

                    <input
                      type="text"
                      name="zipLocation"
                      placeholder="Zip Location"
                      value={formData.zipLocation}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <input
                      type="text"
                      name="destination"
                      placeholder="Destination"
                      value={formData.destination}
                      onChange={handleFormChange}
                      onBlur={handleLocationChange}
                      style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                      }}
                      required
                    />
                    <div style={{ marginBottom: '10px' }}>
                      <textarea
                        placeholder="Your Message"
                        rows="4"
                        style={{
                          width: '100%',
                          padding: '10px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      style={{
                        width: '100%',
                        padding: '12px',
                        backgroundColor: '#495057',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: '16px',
                        cursor: 'pointer',
                        textAlign: 'center',
                      }}
                    >
                      Book Now
                    </button>
                  </form>
                </div>
              </div>
            )}

          </div>
        </div>;
    }
  };



  return (


    <div>
      {/* Hero Section */}
      <div>
        {/* Hero Section */}
        <div>
          <div className="hero-area overly-style-1 opacity-point-4" style={{ position: 'relative' }}>
            <img
              className="banner-image"
              src={bannerImage}
              alt="About AA Applications Banner"
              style={{ width: '100%', height: 'auto' }}
            />

            {/* Content Wrapper */}
            <div
              className="hero-content-wrapper"
              style={{
                display: 'flex',          // Use Flexbox for layout
                justifyContent: 'space-between',  // Space between text and form
                alignItems: 'center',     // Center vertically
                position: 'absolute',     // Position over the image
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',             // Adjust width as needed
              }}
            >
              {/* Left Content - THE DESIGN Text */}
              <div className="hero-text" style={{ flex: '1' }}>
                <h1 style={{ color: '#fff', fontSize: '38px', fontFamily: 'Raqupine Regular', textAlign: 'center', marginTop: '380px' }}>
                  THE LOGISTIC
                </h1>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Sticky Icon Slider */}
      <div>
        <div
          className="icon-slider-wrapper"
          style={{
            position: 'sticky',
            top: `${navbarHeight}px`,
            backgroundColor: '#def2f7',
            zIndex: 997,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            height: '100px',
            width: '100%'
          }}
        >
          <Slider {...settings}>

            {/* 
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('RESIDENTAL_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>BIKES</p>
              </a>
            </div> */}



            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('COMMERCIAL_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>MINI</p>
              </a>
            </div>
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('HOSPITALITY_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faHospital} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>lARGE</p>
              </a>
            </div>
            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('SHOWCASE_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faTree} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>MIDIUAM</p>
              </a>
            </div>
            {/* <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('OUTDOOR_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>OUTDOOR INTERIOPRS</p>
              </a>
            </div>


            <div style={{ textAlign: 'center' }}>
              <a
                className="filter"
                onClick={() => setSelectedIcon('SPECIALTY_INTERIORS')}
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>SPECIALTY INTERIORS</p>
              </a>
            </div> */}
          </Slider>
        </div>


        {/* Dynamic Content Section */}
        <div className="dynamic-content-section" style={{}}>
          {renderContent()}
        </div>
      </div>
    </div>


  );
};

export default WhatWeDo;






