






// import React, { useEffect, useState } from 'react';

// import bannerImage from '../img/aaaservices/BAIINER.jpg';
// import abouthome from '../img/banner/maxresdefault.jpg';
// import abouthome1 from '../img/banner/honda-cb-twister-500x500.webp'
// import abouthome2 from '../img/aaaservices/eicher.jpg';
// import abouthome3 from '../img/aaaservices/MIDUAM.avif';
// import abouthome4 from '../img/aaaservices/BHART4.png';
// import abouthome5 from '..//img/aaaservices/motor2.jpg';
// import abouthome6 from '../img/aaaservices/TRUCK2.avif';

// import Slider from 'react-slick';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faHome,
//   faBuilding,
//   faHospital,
//   faTree,
//   faStar,
//   faEye,
// } from '@fortawesome/free-solid-svg-icons';

// import galleryImg1 from '../img/banner/HERO 1.jpeg';
// import galleryImg2 from '../img/banner/HERO2.jpg';
// import galleryImg3 from '../img/banner/HERO3.jpg';
// import galleryImg4 from '../img/banner/HERO4.avif';
// import galleryImg5 from '../img/banner/HERO 5.webp';
// import galleryImg6 from '../img/banner/HERO6.webp';
// import galleryImg7 from '../img/banner/HERO7.jpeg';
// import galleryImg8 from '../img/banner/HERO8.webp';




// import galleryImg9 from '../img/banner/HONDA1.jpg';
// import galleryImg10 from '../img/banner/HONDA2.jpg';
// import galleryImg11 from '../img/banner/HONDA3.jpg';
// import galleryImg12 from '../img/banner/HONDA4.jpg';
// import galleryImg13 from '../img/banner/HONDA5.jpg';
// import galleryImg14 from '../img/banner/HONDA6.jpg';
// import galleryImg15 from '../img/banner/HONDA7.jpg';
// import galleryImg16 from '../img/banner/HONDA8.jpg';




// import galleryImg17 from '../img/aaaservices/EICHER1.jpg';
// import galleryImg18 from '../img/aaaservices/EICHER2.jpg';
// import galleryImg19 from '../img/aaaservices/EICHER3.jpg';
// import galleryImg20 from '../img/aaaservices/EICHER4.jpg';
// import galleryImg21 from '../img/aaaservices/EICHER5.webp';
// import galleryImg22 from '../img/aaaservices/EICHER6.avif';
// import galleryImg23 from '../img/aaaservices/EICHER4.jpg';
// import galleryImg24 from '../img/aaaservices/EICHER7.JPG';




// import galleryImg25 from '../img/aaaservices/baiiner1.jpg';
// import galleryImg26 from '../img/banner/BHART1.jpg';
// import galleryImg27 from '../img/aaaservices/BHART 2.jpg';
// import galleryImg28 from '../Component/BHART3.JPG';
// import galleryImg29 from '../Component/BHART3.JPG';
// import galleryImg30 from '../img/aaaservices/TRUCKS12.webp';
// import galleryImg31 from '../img/aaaservices/TRUCK5.jpg';
// import galleryImg32 from '../img/aaaservices/EICHER7.JPG';



// import galleryImg33 from '../img/aaaservices/TRUCK11.avif';
// import galleryImg34 from '../img/aaaservices/TRUCKS12.webp';
// import galleryImg35 from '../img/aaaservices/TRUCK3.avif';
// import galleryImg36 from '../img/aaaservices/TRUCKS12.webp';
// import galleryImg37 from '../img/aaaservices/TRUCK5.jpg';
// import galleryImg38 from '../img/aaaservices/TRUCK6.jpg';
// import galleryImg39 from '../img/aaaservices/TRUCK7.jpg';
// import galleryImg40 from '../img/aaaservices/TRUCK8.webp';



// import galleryImg41 from '../img/aaaservices/MOTOR1.jpg';
// import galleryImg42 from '../img/aaaservices/motor2.jpg';
// import galleryImg43 from '../img/aaaservices/motor3.jpg';
// import galleryImg44 from '../img/aaaservices/motor4.webp';
// import galleryImg45 from '../img/aaaservices/motor5.jpg';
// import galleryImg46 from '../img/aaaservices/motor6.jpg';
// import galleryImg47 from '../img/aaaservices/motor8.png';
// import galleryImg48 from '../img/aaaservices/MOTOR1.jpg';


// import galleryImg49 from '../img/aaaservices/MOTOR1.jpg';
// import galleryImg50 from '../img/aaaservices/motor2.jpg';
// import galleryImg51 from '../img/aaaservices/TRUCKS12.webp';
// import galleryImg52 from '../img/aaaservices/motor4.webp';
// import galleryImg53 from '../img/aaaservices/motor5.jpg';
// import galleryImg54 from '../img/aaaservices/motor6.jpg';
// import galleryImg55 from '../img/aaaservices/TRUCKS12.webp';
// import galleryImg56 from '../img/aaaservices/MOTOR1.jpg';



// import ReactImageLightbox from 'react-image-lightbox';
// const WhatWeDo = () => {
//   const [showModal, setShowModal] = useState(false);

//   const [navbarHeight, setNavbarHeight] = useState(93);
//   const [selectedIcon, setSelectedIcon] = useState('RESIDENTIAL_INTERIORS'); // Default selected content
//   const [activeFilter, setActiveFilter] = useState('all');
//   const [isPopupOpen, setIsPopupOpen] = useState(false);

//   // Function to open the popup  all other related contents 
//   const handleOpenPopup = () => {
//     setIsPopupOpen(true);
//   };

//   // Function to close the popup
//   const handleClosePopup = () => {
//     setIsPopupOpen(false);
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     const navbar = document.querySelector('.navbar');
//     if (navbar) {
//       setNavbarHeight(navbar.offsetHeight);
//     }
//   }, []);

//   const [isOpen, setIsOpen] = useState(false);
//   const [currentImage, setCurrentImage] = useState(null);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     message: "",
//   });

//   const openModal = (imgSrc, alt) => {
//     setCurrentImage({ imgSrc, alt });
//     setIsOpen(true);
//   };

//   const closeModal = () => {
//     setCurrentImage(null);
//     setIsOpen(false);
//   };

//   const handleFormChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission (e.g., send data to server)
//     console.log(formData);
//   };

//   const styles = {
//     galleryArea: {
//       padding: "20px",
//       display: "flex",
//       flexWrap: "wrap",
//       gap: "10px",
//       justifyContent: "center",
//     },
//     imageContainer: {
//       cursor: "pointer",
//       marginBottom: "10px",
//     },
//     image: {
//       width: "100%",
//       height: "350px",
//       borderRadius: "5px",
//     },
//     modalOverlay: {
//       position: "fixed",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       background: "rgba(0, 0, 0, 0.8)",
//       zIndex: 1000,
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     },
//     modalContent: {
//       position: "relative",
//       background: "#fff",
//       padding: "20px",
//       borderRadius: "8px",
//       maxWidth: "80%", // Increase the width of the modal
//       maxHeight: "80%",
//       display: "flex", // Make the modal a flex container
//       flexDirection: "row", // Display items side by side
//       justifyContent: "space-between", // Space between image and form
//       alignItems: "flex-start", // Align image and form at the top
//       width: '70%',
//     },
//     modalImage: {
//       width: "45%", // Adjust image width to 45% so there is space for the form
//       height: "auto",
//       objectFit: "contain",
//       borderRadius: "8px",
//     },
//     closeButton: {
//       position: "absolute",
//       top: "-3px",
//       right: "10px",
//       background: "transparent",
//       border: "none",
//       fontSize: "24px",
//       cursor: "pointer",
//       color: "#000",
//     },
//     form: {
//       display: "flex",
//       flexDirection: "column",
//       gap: "10px",
//       width: "45%", // Adjust form width to 45% to fit alongside the image
//     },
//     input: {
//       padding: "10px",
//       fontSize: "14px",
//       border: "1px solid #ccc",
//       borderRadius: "5px",
//     },
//     button: {
//       padding: "10px",
//       fontSize: "16px",
//       backgroundColor: "#007bff",
//       color: "#fff",
//       border: "none",
//       borderRadius: "5px",
//       cursor: "pointer",
//     },
//   };




//   const settings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     pauseOnHover: true,
//     arrows: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: { slidesToShow: 2, slidesToScroll: 1 },
//       },
//       {
//         breakpoint: 768,
//         settings: { slidesToShow: 1, slidesToScroll: 1 },
//       },
//     ],
//   };

//   const renderContent = () => {
//     switch (selectedIcon) {
//       case 'THE_DESIGN':
//         return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome6} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <span
//                       style={{
//                         fontFamily: 'Raqupine Regular',
//                         display: 'inline-block',
//                         borderBottom: '2px solid black',
//                         paddingBottom: '2px',
//                         fontSize: '25px',
//                         color: 'grey',
//                       }}
//                     > TRUCKS
//                     </span>
//                   </div>
//                   <div className="textdetail">
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span>The Interiors transforms your space with cutting-edge virtual reality technology, allowing you to experience your dream interior before it’s built. We blend innovation with creativity, offering immersive design solutions that bring your ideas to life. Our team works closely with you to craft interiors that are both functional and aesthetically pleasing.
//                       .
//                     </p>
//                   </div>
//                   <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                   </p>
//                   <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                     <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> With MODULEX Interiors, you can explore and refine every detail of your design in a fully interactive 3D environment. Step into the future of interior design and watch your vision come alive like never before. </p>

//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//           <div>
//             {/* Adjust the container class to span the full width */}

//           </div>
//         </div>

//       case 'COMMERCIAL_INTERIORS':
//         return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome1} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
//                       HONDA <span style={{ color: "#3395AD" }}>BIKES</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//                   </div>
//                   <div className="textdetail">
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> interiors, we specialize in creating functional and aesthetically pleasing commercial interiors that enhance productivity and reflect your brand’s identity. Our expertise spans office spaces, retail stores, restaurants, and more, with designs that prioritize both efficiency and visual appeal. We integrate advanced technology, ergonomic furniture, and innovative layouts to craft environments where employees thrive and customers feel welcome.
//                     </p>
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                     </p>
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> Whether it’s a modern office with flexible workstations or a chic retail space with engaging displays, our tailored solutions ensure your commercial interiors are both practical and inspiring, perfectly aligned with your business goals </p>

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//         </section>
//           <div>
//             {/* Adjust the container class to span the full width */}

//           </div>
//         </div>
//       case 'HOSPITALITY_INTERIORS':
//         return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome2} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
//                       EICHER<span style={{ color: "#3395AD" }}>TRUCK</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//                   </div>
//                   <div className="textdetail">
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> Our Hospitality Interiors are designed to create unforgettable experiences for guests, blending luxury and comfort with functional elegance. We specialize in crafting spaces that cater to the unique needs of hotels, resorts, and restaurants, ensuring each area—from lobbies to guest rooms—exudes warmth and sophistication..
//                     </p>
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> Our designs incorporate cutting-edge technology, high-quality materials, and thoughtful layouts to enhance both aesthetics and efficiency. Whether it's a serene spa, a stylish dining area, or a welcoming reception, we focus on creating environments that leave a lasting impression, making every stay or visit a memorable one. </p>

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//           <div>
//             {/* Adjust the container class to span the full width */}

//           </div>
//         </div>
//       case 'SHOWCASE_INTERIORS':
//         return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome3} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
//                       MEDIUAM <span style={{ color: "#3395AD" }}>TRUCKS </span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//                   </div>
//                   <div className="textdetail">
//                     <ul>
//                       <li>Gross Vehicle Weight Rating (GVWR): 14,001 to 26,000 lbs (Class 4 to Class 6)</li>
//                       <li>Engine Power: 150-300 horsepower, diesel or gasoline options.</li>
//                       <li>Payload Capacity: Typically 4,000 to 10,000 lbs</li>
//                       <li>Common Configurations: Box trucks, tow trucks, delivery trucks, and flatbeds.</li>
//                       <li>Fuel Efficiency: Ranges from 8 to 15 mpg depending on load and engine type.</li>
//                     </ul>
//                     <div className="button d-flex justify-content-center">
//                     <button
//                     type="button"
//                     id="send-contact-1"
//                     className="btn-contact"
//                     style={{ justifyContent: "center" }}
//                     onClick={handleOpenPopup}
//                   >
//                   Book Now
//                   </button>

//                   {isPopupOpen && (
//                     <div
//                       style={{
//                         position: "fixed",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                         backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         zIndex: 1000,
//                       }}
//                       >
//                       <div
//                         style={{
//                           backgroundColor: "#fff",
//                           padding: "20px",
//                           borderRadius: "10px",
//                           boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
//                           position: "relative",
//                           width: "400px",
//                           textAlign: "center",
//                         }}
//                       >
//                         <h2>Enquiry Form</h2>
//                         <p>Fill in your details and we’ll get back to you shortly.</p>

//                         <button
//                           style={{
//                             position: "absolute",
//                             top: "10px",
//                             right: "10px",
//                             background: "transparent",
//                             border: "none",
//                             fontSize: "20px",
//                             cursor: "pointer",
//                           }}
//                           onClick={handleClosePopup}
//                         >
//                           &times;
//                         </button>

//                         {/* Form inside the popup */}
//                         <form>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="text"
//                               placeholder="Your Name"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="email"
//                               placeholder="Your Email"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="mobile number"
//                               placeholder="Your Mobile Number"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <textarea
//                               placeholder="Your Message"
//                               rows="4"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             ></textarea>
//                           </div>
//                           <button
//                             type="submit"
//                             style={{
//                               padding: "10px 20px",
//                               backgroundColor: "#000",
//                               color: "#fff",
//                               border: "none",
//                               borderRadius: "5px",
//                               cursor: "pointer",
//                             }}
//                           >
//                             Submit
//                           </button>
//                         </form>
//                       </div>
//                     </div>
//                   )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//           <div>
//             {/* Adjust the container class to span the full width */}

//           </div>
//         </div>

//       case 'SPECIALTY_INTERIORS':
//         return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome5} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
//                       MINI <span style={{ color: "#3395AD" }}> TRUCK</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//                   </div>
//                   <div className="textdetail">
//                     <ul>
//                       <li>Gross Vehicle Weight (GVW): Up to 7,000 lbs (around 3,200 kg).</li>
//                       <li>Engine Size: 600cc to 1,500cc, mostly petrol or diesel.</li>
//                       <li>Payload Capacity: 1,000 to 1,500 lbs (450 to 700 kg).</li>
//                       <li>Fuel Efficiency: 15 to 25 mpg or more, depending on the model.</li>
//                       <li>Common Uses: Light cargo transport, delivery, and agricultural purposes.</li>
//                     </ul>
//                     <div className="button d-flex justify-content-center">
//                     <button
//                     type="button"
//                     id="send-contact-1"
//                     className="btn-contact"
//                     style={{ justifyContent: "center" }}
//                     onClick={handleOpenPopup}
//                   >
//                   Book Now
//                   </button>

//                   {isPopupOpen && (
//                     <div
//                       style={{
//                         position: "fixed",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                         backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         zIndex: 1000,
//                       }}
//                       >
//                       <div
//                         style={{
//                           backgroundColor: "#fff",
//                           padding: "20px",
//                           borderRadius: "10px",
//                           boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
//                           position: "relative",
//                           width: "400px",
//                           textAlign: "center",
//                         }}
//                       >
//                         <h2>Enquiry Form</h2>
//                         <p>Fill in your details and we’ll get back to you shortly.</p>

//                         <button
//                           style={{
//                             position: "absolute",
//                             top: "10px",
//                             right: "10px",
//                             background: "transparent",
//                             border: "none",
//                             fontSize: "20px",
//                             cursor: "pointer",
//                           }}
//                           onClick={handleClosePopup}
//                         >
//                           &times;
//                         </button>

//                         {/* Form inside the popup */}
//                         <form>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="text"
//                               placeholder="Your Name"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="email"
//                               placeholder="Your Email"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="mobile number"
//                               placeholder="Your Mobile Number"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <textarea
//                               placeholder="Your Message"
//                               rows="4"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             ></textarea>
//                           </div>
//                           <button
//                             type="submit"
//                             style={{
//                               padding: "10px 20px",
//                               backgroundColor: "#000",
//                               color: "#fff",
//                               border: "none",
//                               borderRadius: "5px",
//                               cursor: "pointer",
//                             }}
//                           >
//                             Submit
//                           </button>
//                         </form>
//                       </div>
//                     </div>
//                   )}

//                     </div>

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//           <div>
//             {/* Adjust the container class to span the full width */}

//           </div>
//         </div>

//       case 'OUTDOOR_INTERIORS':
//         return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome4} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
//                       LARGE <span style={{ color: "#3395AD" }}> TRUCK</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//                   </div>
//                   <div className="textdetail">
//                     <ul>
//                       <li>Gross Vehicle Weight Rating (GVWR): 26,001 lbs and above (up to 80,000 lbs or more)</li>
//                       <li>Engine Power: 300-600 horsepower, with diesel engines being the standard.</li>
//                       <li>Torque: 1,000 to 2,500 lb-ft, depending on the model and purpose.</li>
//                       <li>Payload Capacity: 15,000 to 50,000 lbs, depending on configuration.</li>
//                       <li>Common Types: Semi-trucks, dump trucks, heavy-duty tow trucks, and tankers.</li>
//                     </ul>
//                     <div className="button d-flex justify-content-center">
//                     <button
//                     type="button"
//                     id="send-contact-1"
//                     className="btn-contact"
//                     style={{ justifyContent: "center" }}
//                     onClick={handleOpenPopup}
//                   >
//                   Book Now
//                   </button>

//                   {isPopupOpen && (
//                     <div
//                       style={{
//                         position: "fixed",
//                         top: 0,
//                         left: 0,
//                         width: "100%",
//                         height: "100%",
//                         backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         zIndex: 1000,
//                       }}
//                       >
//                       <div
//                         style={{
//                           backgroundColor: "#fff",
//                           padding: "20px",
//                           borderRadius: "10px",
//                           boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
//                           position: "relative",
//                           width: "400px",
//                           textAlign: "center",
//                         }}
//                       >
//                         <h2>Enquiry Form</h2>
//                         <p>Fill in your details and we’ll get back to you shortly.</p>

//                         <button
//                           style={{
//                             position: "absolute",
//                             top: "10px",
//                             right: "10px",
//                             background: "transparent",
//                             border: "none",
//                             fontSize: "20px",
//                             cursor: "pointer",
//                           }}
//                           onClick={handleClosePopup}
//                         >
//                           &times;
//                         </button>

//                         {/* Form inside the popup */}
//                         <form>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="text"
//                               placeholder="Your Name"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="email"
//                               placeholder="Your Email"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <input
//                               type="mobile number"
//                               placeholder="Your Mobile Number"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             />
//                           </div>
//                           <div style={{ marginBottom: "10px" }}>
//                             <textarea
//                               placeholder="Your Message"
//                               rows="4"
//                               style={{
//                                 width: "100%",
//                                 padding: "10px",
//                                 borderRadius: "5px",
//                                 border: "1px solid #ccc",
//                               }}
//                             ></textarea>
//                           </div>
//                           <button
//                             type="submit"
//                             style={{
//                               padding: "10px 20px",
//                               backgroundColor: "#000",
//                               color: "#fff",
//                               border: "none",
//                               borderRadius: "5px",
//                               cursor: "pointer",
//                             }}
//                           >
//                             Submit
//                           </button>
//                         </form>
//                       </div>
//                     </div>
//                   )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//           <div>
//             {/* Modal */}
//       {showModal && (
//         <div className="modal fade show" tabIndex="-1" style={{ display: 'block', background: 'rgba(0, 0, 0, 0.5)' }}>
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h5 className="modal-title">Booking Form</h5>
//                 <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
//               </div>
//               <div className="modal-body">
//                 <form>
//                   <div className="mb-3">
//                     <label htmlFor="name" className="form-label">Name</label>
//                     <input type="text" className="form-control" id="name" required />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="email" className="form-label">Email</label>
//                     <input type="email" className="form-control" id="email" required />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="date" className="form-label">Booking Date</label>
//                     <input type="date" className="form-control" id="date" required />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="details" className="form-label">Details</label>
//                     <textarea className="form-control" id="details" rows="3" required></textarea>
//                   </div>
//                   <button type="submit" className="btn btn-success">Submit</button>
//                 </form>
//               </div>
//               <div className="modal-footer">
//                 <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
//                   Close
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//       )}
//     </div>

//           </div>


//       case 'RESIDENTAL_INTERIORS':
//         return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
//                       HERO <span style={{ color: "#3395AD" }}> BIKES</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//                   </div>
//                   <div className="textdetail">
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> Outdoor and Landscape Interiors transform exterior spaces into extensions of your living area. Our designs include Stylish Patios with comfortable seating and durable materials for relaxation and entertaining, Elegant Pool Areas featuring luxurious pools, lounging spaces, and outdoor kitchens, and Beautiful Garden Spaces with thoughtfully designed landscaping, pathways, and lighting to create serene environments. .,
//                     </p>
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> We integrate Custom Features like built-in fire pits, outdoor fireplaces, and pergolas to enhance functionality and aesthetics. Each project is tailored to complement your home's style while maximizing outdoor enjoyment and usability. </p>

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//           <div>
//             {/* Adjust the container class to span the full width */}

//           </div>
//         </div>


//       default:
//         return <div><section className="no-top no-bottom bggray" aria-label="abouthome">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-6 p-0">
//                 <img src={abouthome6} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
//               </div>
//               <div className="col-md-6 centered">
//                 <div className="detailcontent">
//                   <div
//                     className="subheading"
//                     style={{
//                       color: '#1c1d1d',
//                       fontSize: '48px',
//                       textAlign: 'center',
//                       marginBottom: '11px',
//                     }}
//                   >
//                     <span
//                       style={{
//                         fontFamily: 'Raqupine Regular',
//                         display: 'inline-block',
//                         borderBottom: '2px solid black',
//                         paddingBottom: '2px',
//                         fontSize: '25px',
//                         color: 'grey',
//                       }}
//                     > THE TRUCK
//                     </span>
//                   </div>
//                   <div className="textdetail">
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span>The Interiors transforms your space with cutting-edge virtual reality technology, allowing you to experience your dream interior before it’s built. We blend innovation with creativity, offering immersive design solutions that bring your ideas to life. Our team works closely with you to craft interiors that are both functional and aesthetically pleasing. .,
//                     </p>
//                     <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                       <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> With MODULEX Interiors, you can explore and refine every detail of your design in a fully interactive 3D environment. Step into the future of interior design and watch your vision come alive like never before. </p>

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//           <div>
//             {/* Adjust the container class to span the full width */}


//           </div>
//         </div>;
//     }
//   };

//   return (


//     <div>
//       {/* Hero Section */}
//       <div>
//         {/* Hero Section */}
//         <div>
//           <div className="hero-area overly-style-1 opacity-point-4" style={{ position: 'relative' }}>
//             <img
//               className="banner-image"
//               src={bannerImage}
//               alt="About AA Applications Banner"
//               style={{ width: '100%', height: 'auto' }}
//             />

//             {/* Content Wrapper */}
//             <div
//               className="hero-content-wrapper"
//               style={{
//                 display: 'flex',          // Use Flexbox for layout
//                 justifyContent: 'space-between',  // Space between text and form
//                 alignItems: 'center',     // Center vertically
//                 position: 'absolute',     // Position over the image
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 width: '80%',             // Adjust width as needed
//               }}
//             >
//               {/* Left Content - THE DESIGN Text */}
//               <div className="hero-text" style={{ flex: '1' }}>
//                 <h1 style={{ color: '#fff', fontSize: '48px', fontFamily: 'Raqupine Regular', textAlign: 'center', marginTop: '380PX' }}>
//                   THE TRUCKS
//                 </h1>
//               </div>

//             </div>
//           </div>
//         </div>
//       </div>






//       {/* Sticky Icon Slider */}
//       <div>
//         <div
//           className="icon-slider-wrapper"
//           style={{
//             position: 'sticky',
//             top: `${navbarHeight}px`,
//             backgroundColor: '#def2f7',
//             zIndex: 997,
//             boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//             height: '100px',
//             width: '100%'
//           }}
//         >
//           <Slider {...settings}>

//             {/* 
//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter"
//                 onClick={() => setSelectedIcon('RESIDENTAL_INTERIORS')}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '33px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <FontAwesomeIcon icon={faHome} style={{ marginBottom: '5px', fontSize: '24px' }} />
//                 <p>HERO</p>
//               </a>
//             </div>



//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter"
//                 onClick={() => setSelectedIcon('COMMERCIAL_INTERIORS')}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '33px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: '5px', fontSize: '24px' }} />
//                 <p>GLOBETRAKLOGISTICS</p>
//               </a> */}

//             {/* <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter"
//                 onClick={() => setSelectedIcon('HOSPITALITY_INTERIORS')}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '33px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <FontAwesomeIcon icon={faHospital} style={{ marginBottom: '5px', fontSize: '24px' }} />
//                 <p>EICHER</p>
//               </a>
//             </div> */}
//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter"
//                 onClick={() => setSelectedIcon('SHOWCASE_INTERIORS')}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '33px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <FontAwesomeIcon icon={faTree} style={{ marginBottom: '5px', fontSize: '24px' }} />
//                 <p>MEDIUAM</p>
//               </a>
//             </div>
//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter"
//                 onClick={() => setSelectedIcon('OUTDOOR_INTERIORS')}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '33px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <FontAwesomeIcon icon={faEye} style={{ marginBottom: '5px', fontSize: '24px' }} />
//                 <p>LARGE</p>
//               </a>
//             </div>


//             <div style={{ textAlign: 'center' }}>
//               <a
//                 className="filter"
//                 onClick={() => setSelectedIcon('SPECIALTY_INTERIORS')}
//                 style={{
//                   textDecoration: 'none',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   alignItems: 'center',
//                   marginTop: '33px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 <FontAwesomeIcon icon={faStar} style={{ marginBottom: '5px', fontSize: '24px' }} />
//                 <p>MINI</p>
//               </a>
//             </div>
//           </Slider>
//         </div>


//         {/* Dynamic Content Section */}
//         <div className="dynamic-content-section" style={{}}>
//           {renderContent()}
//         </div>
//       </div>
//     </div>


//   );
// };

// export default WhatWeDo;








import React, { useEffect, useState } from 'react';

import bannerImage from '../img/aaaservices/BHART.jpg';
import abouthome from '../img/aaaservices/BUS.webp';
import abouthome1 from '../img/banner/honda-cb-twister-500x500.webp'
import abouthome2 from '../img/aaaservices/eicher.jpg';
import abouthome3 from '../img/banner/TOYOTA9.webp';
import abouthome4 from '../img/banner/TOYPTA.webp';
import abouthome5 from '../img/banner/KIA4.jpg';
import abouthome6 from '../img/aaaservices/TRUCK3.avif';

import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import galleryImg1 from '../img/banner/HERO 1.jpeg';
import galleryImg2 from '../img/banner/HERO2.jpg';
import galleryImg3 from '../img/banner/HERO3.jpg';
import galleryImg4 from '../img/banner/HERO4.avif';
import galleryImg5 from '../img/banner/HERO 5.webp';
import galleryImg6 from '../img/banner/HERO6.webp';
import galleryImg7 from '../img/banner/HERO7.jpeg';
import galleryImg8 from '../img/banner/HERO8.webp';




import galleryImg9 from '../img/banner/HONDA1.jpg';
import galleryImg10 from '../img/banner/HONDA2.jpg';
import galleryImg11 from '../img/banner/HONDA3.jpg';
import galleryImg12 from '../img/banner/HONDA4.jpg';
import galleryImg13 from '../img/banner/HONDA5.jpg';
import galleryImg14 from '../img/banner/HONDA6.jpg';
import galleryImg15 from '../img/banner/HONDA7.jpg';
import galleryImg16 from '../img/banner/HONDA8.jpg';




import galleryImg17 from '../img/aaaservices/EICHER1.jpg';
import galleryImg18 from '../img/aaaservices/EICHER2.jpg';
import galleryImg19 from '../img/aaaservices/EICHER3.jpg';
import galleryImg20 from '../img/aaaservices/EICHER4.jpg';
import galleryImg21 from '../img/aaaservices/EICHER5.webp';
import galleryImg22 from '../img/aaaservices/EICHER6.avif';
import galleryImg23 from '../img/aaaservices/EICHER4.jpg';
import galleryImg24 from '../img/aaaservices/EICHER7.JPG';




import galleryImg25 from '../img/aaaservices/baiiner1.jpg';
import galleryImg26 from '../img/banner/BHART1.jpg';
import galleryImg27 from '../img/aaaservices/BHART 2.jpg';
import galleryImg28 from '../Component/BHART3.JPG';
import galleryImg29 from '../Component/BHART3.JPG';
import galleryImg30 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg31 from '../img/aaaservices/TRUCK5.jpg';
import galleryImg32 from '../img/aaaservices/EICHER7.JPG';



import galleryImg33 from '../img/aaaservices/TRUCK11.avif';
import galleryImg34 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg35 from '../img/aaaservices/TRUCK3.avif';
import galleryImg36 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg37 from '../img/aaaservices/TRUCK5.jpg';
import galleryImg38 from '../img/aaaservices/TRUCK6.jpg';
import galleryImg39 from '../img/aaaservices/TRUCK7.jpg';
import galleryImg40 from '../img/aaaservices/TRUCK8.webp';



import galleryImg41 from '../img/aaaservices/MOTOR1.jpg';
import galleryImg42 from '../img/aaaservices/motor2.jpg';
import galleryImg43 from '../img/aaaservices/motor3.jpg';
import galleryImg44 from '../img/aaaservices/motor4.webp';
import galleryImg45 from '../img/aaaservices/motor5.jpg';
import galleryImg46 from '../img/aaaservices/motor6.jpg';
import galleryImg47 from '../img/aaaservices/motor8.png';
import galleryImg48 from '../img/aaaservices/MOTOR1.jpg';


import galleryImg49 from '../img/aaaservices/MOTOR1.jpg';
import galleryImg50 from '../img/aaaservices/motor2.jpg';
import galleryImg51 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg52 from '../img/aaaservices/motor4.webp';
import galleryImg53 from '../img/aaaservices/motor5.jpg';
import galleryImg54 from '../img/aaaservices/motor6.jpg';
import galleryImg55 from '../img/aaaservices/TRUCKS12.webp';
import galleryImg56 from '../img/aaaservices/MOTOR1.jpg';


import { useNavigate } from 'react-router-dom';
import ReactImageLightbox from 'react-image-lightbox';
const WhatWeDo = () => {
  const [showModal, setShowModal] = useState(false);

  const [navbarHeight, setNavbarHeight] = useState(93);
  const [selectedIcon, setSelectedIcon] = useState('RESIDENTIAL_INTERIORS'); // Default selected content
  const [activeFilter, setActiveFilter] = useState('all');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();  // Use the navigate function
  // Function to open the popup  all other related contents 
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const openModal = (imgSrc, alt) => {
    setCurrentImage({ imgSrc, alt });
    setIsOpen(true);
  };

  const closeModal = () => {
    setCurrentImage(null);
    setIsOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to server)
    console.log(formData);
  };
  const handleLocationChange = () => {
    // Example function for calculating distance and amount based on zipLocation or destination
    // You can replace this with your own logic for distance and amount calculations
    if (formData.zipLocation && formData.destination) {
      // Example distance and amount calculation logic
      const distance = Math.random() * 100; // Random distance in km
      const amount = distance * 10; // Example: $10 per km
      setFormData((prevData) => ({
        ...prevData,
        distance: distance.toFixed(2),
        amount: amount.toFixed(2),
      }));
    }
  };

  const styles = {
    galleryArea: {
      padding: "20px",
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      justifyContent: "center",
    },
    imageContainer: {
      cursor: "pointer",
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "350px",
      borderRadius: "5px",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      position: "relative",
      background: "#fff",
      padding: "20px",
      borderRadius: "8px",
      maxWidth: "80%", // Increase the width of the modal
      maxHeight: "80%",
      display: "flex", // Make the modal a flex container
      flexDirection: "row", // Display items side by side
      justifyContent: "space-between", // Space between image and form
      alignItems: "flex-start", // Align image and form at the top
      width: '70%',
    },
    modalImage: {
      width: "45%", // Adjust image width to 45% so there is space for the form
      height: "auto",
      objectFit: "contain",
      borderRadius: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "-3px",
      right: "10px",
      background: "transparent",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
      color: "#000",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "45%", // Adjust form width to 45% to fit alongside the image
    },
    input: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      padding: "10px",
      fontSize: "16px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };




  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2, slidesToScroll: 1 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  const renderContent = () => {
    switch (selectedIcon) {
      case 'THE_DESIGN':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome6} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Raqupine Regular',
                        display: 'inline-block',
                        borderBottom: '2px solid black',
                        paddingBottom: '2px',
                        fontSize: '25px',
                        color: 'grey',
                      }}
                    > TRUCKS
                    </span>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span>The Interiors transforms your space with cutting-edge virtual reality technology, allowing you to experience your dream interior before it’s built. We blend innovation with creativity, offering immersive design solutions that bring your ideas to life. Our team works closely with you to craft interiors that are both functional and aesthetically pleasing.
                      .
                    </p>
                  </div>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                  </p>
                  <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> With MODULEX Interiors, you can explore and refine every detail of your design in a fully interactive 3D environment. Step into the future of interior design and watch your vision come alive like never before. </p>

                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}

          </div>
        </div>

      case 'COMMERCIAL_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome1} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      HONDA <span style={{ color: "#3395AD" }}>BIKES</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> interiors, we specialize in creating functional and aesthetically pleasing commercial interiors that enhance productivity and reflect your brand’s identity. Our expertise spans office spaces, retail stores, restaurants, and more, with designs that prioritize both efficiency and visual appeal. We integrate advanced technology, ergonomic furniture, and innovative layouts to craft environments where employees thrive and customers feel welcome.
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> Whether it’s a modern office with flexible workstations or a chic retail space with engaging displays, our tailored solutions ensure your commercial interiors are both practical and inspiring, perfectly aligned with your business goals </p>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
          <div>
            {/* Adjust the container class to span the full width */}

          </div>
        </div>
      case 'HOSPITALITY_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome2} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      EICHER<span style={{ color: "#3395AD" }}>TRUCK</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> Our Hospitality Interiors are designed to create unforgettable experiences for guests, blending luxury and comfort with functional elegance. We specialize in crafting spaces that cater to the unique needs of hotels, resorts, and restaurants, ensuring each area—from lobbies to guest rooms—exudes warmth and sophistication..
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> Our designs incorporate cutting-edge technology, high-quality materials, and thoughtful layouts to enhance both aesthetics and efficiency. Whether it's a serene spa, a stylish dining area, or a welcoming reception, we focus on creating environments that leave a lasting impression, making every stay or visit a memorable one. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}

          </div>
        </div>
      case 'SHOWCASE_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome3} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      MEDIUAM <span style={{ color: "#3395AD" }}> CARS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <ul>
                      <li>Gross Vehicle Weight Rating (GVWR): 14,001 to 26,000 lbs (Class 4 to Class 6)</li>
                      <li>Engine Power: 150-300 horsepower, diesel or gasoline options.</li>
                      <li>Payload Capacity: Typically 4,000 to 10,000 lbs</li>
                      <li>Common Configurations: Box trucks, tow trucks, delivery trucks, and flatbeds.</li>
                      <li>Fuel Efficiency: Ranges from 8 to 15 mpg depending on load and engine type.</li>
                    </ul>
                    <div className="button d-flex justify-content-center">
                      <button
                        type="button"
                        id="send-contact-1"
                        className="btn-contact"
                        style={{ justifyContent: "center" }}
                        onClick={handleOpenPopup}
                      >
                        Book Now
                      </button>

                      {isPopupOpen && (
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: "20px",
                              borderRadius: "10px",
                              boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
                              position: "relative",
                              width: "400px",
                              textAlign: "center",
                            }}
                          >
                            <h2>Enquiry Form</h2>
                            <p>Fill in your details and we’ll get back to you shortly.</p>

                            <button
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "transparent",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={handleClosePopup}
                            >
                              &times;
                            </button>

                            {/* Form inside the popup */}
                            <form>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="text"
                                  placeholder="Your Name"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="email"
                                  placeholder="Your Email"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="mobile number"
                                  placeholder="Your Mobile Number"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <textarea
                                  placeholder="Your Message"
                                  rows="4"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                ></textarea>
                              </div>
                              <button
                                type="submit"
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "#000",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}

          </div>
        </div>

      case 'SPECIALTY_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome5} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      MINI <span style={{ color: "#3395AD" }}> CARS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <ul>
                      <li>Gross Vehicle Weight (GVW): Up to 7,000 lbs (around 3,200 kg).</li>
                      <li>Engine Size: 600cc to 1,500cc, mostly petrol or diesel.</li>
                      <li>Payload Capacity: 1,000 to 1,500 lbs (450 to 700 kg).</li>
                      <li>Fuel Efficiency: 15 to 25 mpg or more, depending on the model.</li>
                      <li>Common Uses: Light cargo transport, delivery, and agricultural purposes.</li>
                    </ul>
                    <div className="button d-flex justify-content-center">
                      <button
                        type="button"
                        id="send-contact-1"
                        className="btn-contact"
                        style={{ justifyContent: "center" }}
                        onClick={handleOpenPopup}
                      >
                        Book Now
                      </button>

                      {isPopupOpen && (
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: "20px",
                              borderRadius: "10px",
                              boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
                              position: "relative",
                              width: "400px",
                              textAlign: "center",
                            }}
                          >
                            <h2>Enquiry Form</h2>
                            <p>Fill in your details and we’ll get back to you shortly.</p>

                            <button
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "transparent",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={handleClosePopup}
                            >
                              &times;
                            </button>

                            {/* Form inside the popup */}
                            <form>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="text"
                                  placeholder="Your Name"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="email"
                                  placeholder="Your Email"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="mobile number"
                                  placeholder="Your Mobile Number"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <textarea
                                  placeholder="Your Message"
                                  rows="4"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                ></textarea>
                              </div>
                              <button
                                type="submit"
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "#000",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      )}

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}

          </div>
        </div>

      case 'OUTDOOR_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome4} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "557px" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      LARGE <span style={{ color: "#3395AD" }}> CARS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <ul>
                      <li>Gross Vehicle Weight Rating (GVWR): 26,001 lbs and above (up to 80,000 lbs or more)</li>
                      <li>Engine Power: 300-600 horsepower, with diesel engines being the standard.</li>
                      <li>Torque: 1,000 to 2,500 lb-ft, depending on the model and purpose.</li>
                      <li>Payload Capacity: 15,000 to 50,000 lbs, depending on configuration.</li>
                      <li>Common Types: Semi-trucks, dump trucks, heavy-duty tow trucks, and tankers.</li>
                    </ul>
                    <div className="button d-flex justify-content-center">
                      <button
                        type="button"
                        id="send-contact-1"
                        className="btn-contact"
                        style={{ justifyContent: "center" }}
                        onClick={handleOpenPopup}
                      >
                        Book Now
                      </button>

                      {isPopupOpen && (
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: "20px",
                              borderRadius: "10px",
                              boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
                              position: "relative",
                              width: "400px",
                              textAlign: "center",
                            }}
                          >
                            <h2>Enquiry Form</h2>
                            <p>Fill in your details and we’ll get back to you shortly.</p>

                            <button
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "transparent",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={handleClosePopup}
                            >
                              &times;
                            </button>

                            {/* Form inside the popup */}
                            <form>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="text"
                                  placeholder="Your Name"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="email"
                                  placeholder="Your Email"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="mobile number"
                                  placeholder="Your Mobile Number"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              </div>
                              <div style={{ marginBottom: "10px" }}>
                                <textarea
                                  placeholder="Your Message"
                                  rows="4"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                  }}
                                ></textarea>
                              </div>
                              <button
                                type="submit"
                                style={{
                                  padding: "10px 20px",
                                  backgroundColor: "#000",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Modal */}
            {showModal && (
              <div className="modal fade show" tabIndex="-1" style={{ display: 'block', background: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Booking Form</h5>
                      <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">Name</label>
                          <input type="text" className="form-control" id="name" required />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">Email</label>
                          <input type="email" className="form-control" id="email" required />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="date" className="form-label">Booking Date</label>
                          <input type="date" className="form-control" id="date" required />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="details" className="form-label">Details</label>
                          <textarea className="form-control" id="details" rows="3" required></textarea>
                        </div>
                        <button type="submit" className="btn btn-success">Submit</button>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            )}
          </div>

        </div>


      case 'RESIDENTAL_INTERIORS':
        return <div> <section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      HERO <span style={{ color: "#3395AD" }}> BIKES</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}>TRAVLES</span> Outdoor and Landscape Interiors transform exterior spaces into extensions of your living area. Our designs include Stylish Patios with comfortable seating and durable materials for relaxation and entertaining, Elegant Pool Areas featuring luxurious pools, lounging spaces, and outdoor kitchens, and Beautiful Garden Spaces with thoughtfully designed landscaping, pathways, and lighting to create serene environments. .,
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                      <span style={{ fontFamily: 'Raqupine Regular' }}> TRAVLES </span> We integrate Custom Features like built-in fire pits, outdoor fireplaces, and pergolas to enhance functionality and aesthetics. Each project is tailored to complement your home's style while maximizing outdoor enjoyment and usability. </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
            {/* Adjust the container class to span the full width */}

          </div>
        </div>


      default:
        return <div><section className="no-top no-bottom bggray" aria-label="abouthome">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 p-0">
                <img src={abouthome6} className="imgbg-col" alt="imghome" style={{ width: "100%", height: "auto" }} />
              </div>
              <div className="col-md-6 centered">
                <div className="detailcontent">
                  <div
                    className="subheading"
                    style={{
                      color: '#1c1d1d',
                      fontSize: '48px',
                      textAlign: 'center',
                      marginBottom: '11px',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Raqupine Regular',
                        display: 'inline-block',
                        borderBottom: '2px solid black',
                        paddingBottom: '2px',
                        fontSize: '25px',
                        color: 'grey',
                      }}
                    >
                    </span>
                    <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      LARGE <span style={{ color: "#3395AD" }}>TRUCKS </span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
                  </div>
                  <div className="textdetail">
                    <ul>
                      <li>Gross Vehicle Weight Rating (GVWR): 14,001 to 26,000 lbs (Class 4 to Class 6)</li>
                      <li>Engine Power: 150-300 horsepower, diesel or gasoline options.</li>
                      <li>Payload Capacity: Typically 4,000 to 10,000 lbs</li>
                      <li>Common Configurations: Box trucks, tow trucks, delivery trucks, and flatbeds.</li>
                      <li>Fuel Efficiency: Ranges from 8 to 15 mpg depending on load and engine type.</li>
                    </ul>
                    <div className="button d-flex justify-content-center">
                      <button
                        type="button"
                        id="send-contact-1"
                        className="btn-contact"
                        style={{ justifyContent: "center" }}
                        onClick={handleOpenPopup}
                      >
                        Book Now
                      </button>

                      {isPopupOpen && (
                        <div
                          style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "#fff",
                              padding: "20px",
                              borderRadius: "10px",
                              boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
                              position: "relative",
                              width: "526px",
                              textAlign: "center",
                            }}
                          >
                            <h2>Enquiry Form</h2>
                            <p>Fill in your details and we’ll get back to you shortly.</p>

                            <button
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "transparent",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                              onClick={handleClosePopup}
                            >
                              &times;
                            </button>

                            {/* Form inside the popup */}
                            <form>
                              <div style={{ marginBottom: "10px" }}>
                                <input
                                  type="text"
                                  name="name"
                                  placeholder="Your Name"
                                  value={formData.name}
                                  onChange={handleFormChange}
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  required
                                />
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Your Email"
                                  value={formData.email}
                                  onChange={handleFormChange}
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  required
                                />
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                              <input
                                  type="tel"
                                  name="phone"
                                  placeholder="Phone Number"
                                  value={formData.phone}
                                  onChange={handleFormChange}
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    fontSize: '16px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  maxLength="10" // Limits input to 10 characters
                                  pattern="\d*" // Only numeric values allowed
                                  required
                                />
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <input
                                  type="date"
                                  name="date"
                                  value={formData.date}
                                  onChange={handleFormChange}
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  required
                                />
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <input
                                  type="time"
                                  name="time"
                                  value={formData.time}
                                  onChange={handleFormChange}
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  required
                                />
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <select
                                  name="vanQuantity"
                                  value={formData.vanQuantity}
                                  onChange={handleFormChange}
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  required
                                >
                                  <option value="">Select Van Quantity</option>
                                  <option value="1">1 Van</option>
                                  <option value="2">2 Vans</option>
                                  <option value="3">3 Vans</option>
                                  <option value="4">4 Vans</option>
                                  <option value="5">5 Vans</option>
                                </select>
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <input
                                  type="text"
                                  name="zipLocation"
                                  placeholder="Zip Location"
                                  value={formData.zipLocation}
                                  onChange={handleFormChange}
                                  onBlur={handleLocationChange} // Trigger calculation on blur
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  required
                                />
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <input
                                  type="text"
                                  name="destination"
                                  placeholder="Destination"
                                  value={formData.destination}
                                  onChange={handleFormChange}
                                  onBlur={handleLocationChange} // Trigger calculation on blur
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                  required
                                />
                              </div>
                              <div style={{ marginBottom: '10px' }}>
                                <textarea
                                  name="message"
                                  placeholder="Your Message"
                                  rows="4"
                                  value={formData.message}
                                  onChange={handleFormChange}
                                  style={{
                                    width: '100%',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                  }}
                                ></textarea>
                              </div>

                              {/* Display calculated distance and amount */}
                              {formData.distance && formData.amount && (
                                <div style={{ marginBottom: '10px' }}>
                                  <p>Distance: {formData.distance} km</p>
                                  <p>Amount: ${formData.amount}</p>
                                </div>
                              )}

                              <button
                                type="submit"
                                style={{
                                  width: '100%',
                                  padding: '12px',
                                  backgroundColor: '#495057',
                                  color: '#fff',
                                  border: 'none',
                                  borderRadius: '5px',
                                  fontSize: '16px',
                                  cursor: 'pointer',
                                  textAlign: 'center',
                                }}
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          <div>
          </div>
        </div>
    }
  };

  return (


    <div>
      {/* Hero Section */}
      <div>
        {/* Hero Section */}
        <div>
          <div className="hero-area overly-style-1 opacity-point-4" style={{ position: 'relative' }}>
            <img
              className="banner-image"
              src={bannerImage}
              alt="About AA Applications Banner"
              style={{ width: '100%', height: 'auto' }}
            />

            {/* Content Wrapper */}
            <div
              className="hero-content-wrapper"
              style={{
                display: 'flex',          // Use Flexbox for layout
                justifyContent: 'space-between',  // Space between text and form
                alignItems: 'center',     // Center vertically
                position: 'absolute',     // Position over the image
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',             // Adjust width as needed
              }}
            >
              {/* Left Content - THE DESIGN Text */}
              <div className="hero-text" style={{ flex: '1' }}>
                <h1 style={{ color: '#000', fontSize: '48px', fontFamily: 'Raqupine Regular', textAlign: 'center', }}>
                  LARGE TRUCKS
                </h1>
              </div>

            </div>
          </div>
        </div>
      </div>






      {/* Sticky Icon Slider */}
      <div>
        <div
          className="icon-slider-wrapper"
          style={{
            position: "sticky",
            top: `${navbarHeight}px`,
            backgroundColor: "#def2f7",
            zIndex: 997,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            height: "100px",
            width: '100%'
          }}
        >
          <Slider {...settings}>
            {/* Residential Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/it-services')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHome} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>MIDUAM</p>
              </a>
            </div>
            {/* Commercial Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"


                onClick={() => navigate('/gaming')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>LARGE</p>
              </a>
            </div>
            {/* Hospitality Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/web-mobile-applications')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHospital} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>MINI</p>
              </a>
            </div>
            {/*             
           
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => navigate('/snowcsc')}  // Use navigate to go to the path
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: '33px',
                }}
              >
                <FontAwesomeIcon icon={faTree} style={{ marginBottom: '5px', fontSize: '24px' }} />
                <p>SHOWCASE INTERIORS</p>
              </a>
            </div>


          
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => navigate('/Specialty')} 
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>SPECIALTY INTERIORS</p>
              </a>
            </div>

           
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"

                onClick={() => navigate('/outdoor')}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>OUTDOOR INTERIOPRS</p>
              </a>
            </div> */}
          </Slider>
        </div>


        {/* Dynamic Content Section */}
        <div className="dynamic-content-section" style={{}}>
          {renderContent()}
        </div>
      </div>
    </div>


  );
};

export default WhatWeDo;













































