import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import customersupport from '../img/services-image/customer-support.jpeg'
const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm('service_qa3zbmm', 'template_cezc1ah', e.target, 'BpJS--NtwuBSXs9NC')
      .then((result) => {
        console.log(result.text);
        alert('Success! Your message has been sent.'); // Success alert
        setIsSubmitting(false);
      }, (error) => {
        console.log(error.text);
        alert('Error! Your message could not be sent. Please try again later.'); // Failure alert
        setIsSubmitting(false);
      });
  };

  return (
    <>
      {/* Section: Get in Touch */}
      <section aria-label="section" className="no-bottom">
        <div className="container-fluid">
          <div className="row p-3-vh">
            <div className="col-12 centered my-o">
              <div className="detailcontent text-center p-0">
                <div className="heading" style={{ color: '#100f0f', letterSpacing: '0.025em' }}>
                  Get in touch{' '}
                  <span className="color" style={{ color: '#3395AD', letterSpacing: '0.025em' }}>
                    with us
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      {/* Section: Contact */}
      <section aria-label="contact" style={{ padding: '20px' }}>
        <div className="container-fluid">
          <div className="row p-3-vh">

            {/* Contact Information */}
            <div className="col-md-6">
              <section aria-label="map" className="no-bottom">
                {/* <div className="container-fluid"> */}
                <div className="row p-3-vh">
                  <div className="col-md-12">
                    <div>
                      {/* <img src={customersupport} className="imgbg-col" alt="imghome" style={{ height: '350px' }} /> */}
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7610.7433924449315!2d78.38240638937562!3d17.489761383659552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb918dab342375%3A0x180a04af0c47f594!2sManjeera%20Trinity%20Corporate!5e0!3m2!1sen!2sin!4v1723212055136!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </section>
            </div>

            {/* Contact Form */}
            <div className="col-md-6">
              <form id="form-contact1" className="autoheight" onSubmit={sendEmail}>
                <div className="form-group user-name">
                  <input type="text" name="name" className="form-control" required placeholder="Your Name" />
                </div>
                <div className="form-group user-email">
                  <input type="email" name="email" className="form-control" required placeholder="Your Email" />
                </div>
                <div className="form-group user-phone">
                  <input type="tel" name="phone" className="form-control" required placeholder="Your Phone Number" maxLength="10" pattern="\d{10}" title="Please enter exactly 10 digits" />
                </div>
                <div className="form-group user-message">
                  <textarea name="message" className="form-control" required placeholder="Your Message"></textarea>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button
                    type="submit"
                    id="send-contact-1"
                    className="btn-contact"
                    disabled={isSubmitting}
                  >
                    Send Now
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
