// import React from 'react';
// import abouthome from '../img/banner/about-us.png'
// const AboutCompany = () => {
//   return (
//     <section className="no-top no-bottom bggray" aria-label="abouthome">
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-md-6 p-0">
//         <img src={abouthome}className="imgbg-col" alt="imghome" />
//           </div>
//           <div className="col-md-6 centered">
//             <div className="detailcontent">
//               <div className="subheading" style={{ color: '#1c1d1d', fontSize: '48px', textAlign: 'center', marginBottom: '11px' }}>
//                 About <span style={{ color: '#1c1d1d', fontSize: '48px' }}>Company</span>
//               </div>
//               {/* <div className="heading">Best Interior is Our Passion</div> */}
//               <div className="textdetail">
//               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//               <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span> provides organizations of all sizes with the best, most trustworthy IT solutions. In the ever-evolving technology market, we believe that companies need reliable allies who can guide them through the challenges that accompany technological growth. We provide IT Support, Services and development services - to name a few. Contact us today to find out more.
//                </p>
//                <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                "Providing Automated Products for  Enterprises"
//                In order for enterprises to be fully compliant with regulations, enterprises need to improve business processes, information flow within and across enterprises and provide secure information delivery. </p>
//                <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                <span style={{fontFamily: 'Raqupine Regular' }}>MODULEX</span> provides a customer-focused products specialized in business solutions using advanced technologies.  </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AboutCompany;




// import React from 'react';
// import aboutUsImage from '../img/banner/about-us.png'; // Import the image

// const AboutUs = () => {
//   return (
//     <section className="about-area ptb-100">
//       <div className="container">
//         <div className="row align-items-center">
//           {/* About Content */}
//           <div className="col-lg-6 col-md-12">
//             <div className="about-title">
//               <h2>
//                 <span style={{ fontSize: '42px', color: '#363435' }}>ABOUT US</span>
//               </h2>
//               <h2 style={{ color: '#433d40' }}>
//                 Designing spaces that reflect your style and enhance your living experience.
//               </h2>
//               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                 Welcome to  MODULEX Interiors, where cutting-edge technology meets innovative interior
//                 design. We specialize in creating immersive virtual reality experiences that allow
//                 you to explore and customize your spaces before they become a reality.
//               </p>
//               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
//                 At  MODULEX Interiors, we believe in the power of technology to transform the design
//                 process. Our team of expert designers and MODULEX specialists work together to bring
//                 your ideas to life in a fully interactive 3D environment.
//               </p>
//             </div>
//           </div>

//           {/* About Image */}
//           <div className="col-lg-6 col-md-12">
//             <div className="about-text">
//               <img src={aboutUsImage} alt="About Us" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AboutUs;




import React from 'react';
import aboutUsImage from '../img/banner/about-us.png'; // Import the image

const AboutUs = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          {/* About Content */}
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
          <div className="container" style={{textAlign:'center'}}>
          <div className="row" style={{ paddingBottom: '20px' }}>
            <div className="container">
            <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
                      About <span style={{ color: "#3395AD" }}>Us</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
            </div>
            </div>
              <h2 style={{ color: 'rgb(16 16 14);',fontSize:'20px',fontWeight:'700',fontFamily:'lato,sans,serif' }}>
                Designing spaces that reflect your style and enhance your living experience.
              </h2>
              <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                Welcome to <span style={{fontFamily: 'Raqupine Regular',color:'black'}}> TRAVELS </span> Interiors, where cutting-edge technology meets innovative interior
                design. We specialize in creating immersive virtual reality experiences that allow
                you to explore and customize your spaces before they become a reality.
              </p>
              <p style={{ fontSize: '16px', textAlign: 'justify' }}>
                At <span style={{fontFamily: 'Raqupine Regular',color:'black'}}> TRAVELS </span> Interiors, we believe in the power of technology to transform the design
                process. Our team of expert designers and MODULEX specialists work together to bring
                your ideas to life in a fully interactive 3D environment.
              </p>
            </div>
          </div>

          {/* About Image */}
          <div className="col-lg-6 col-md-12">
            <div className="about-text text-center">
              <img src={aboutUsImage} alt="About Us" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;








