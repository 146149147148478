// import React from 'react';

// const OurCommitments = () => {
//   return (
//     <section id="commitment" style={{ paddingTop: '40px' }}>
//       <div className="container">
//         <div className="row">
//           <section id="cards-containers" style={{ textAlign: 'center' }}>
//             <div className="container">
//             <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
//                       Our <span style={{ color: "#3395AD" }}>Commitments</span>
//                       <span style={{
//                         position: 'absolute',
//                         bottom: '-8px',
//                         left: '50%',
//                         transform: 'translateX(-50%)',
//                         width: '50%',
//                         height: '2px',
//                         backgroundColor: '#000'
//                       }} />
//                     </h1>
//             </div>
//             {/* Our Vision Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                     <i className="fa fa-crosshairs" style={{ color: '#363435' }}></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Vision</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Vision</h4>
//                   <p style={{ color: 'white', fontSize: '13px' }}>
//                     Our vision is to be the premier provider of integrated technology solutions, driving innovation and transformation for businesses worldwide. We aspire to create a future where seamless technology integration accelerates business success and unlocks limitless potential.
//                   </p>
//                 </div>
//               </div>
//             </div>

//             {/* Our Mission Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                     <i className="fa fa-bullseye" style={{ color: '#363435' }}></i>
//                   </div>
//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Mission</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Mission</h4>
//                   <p style={{ color: 'white', fontSize: '12px' }}>
//                     our mission is to empower organizations by delivering customized, cutting-edge technology solutions that drive growth, enhance efficiency, and foster innovation. We are committed to understanding the unique needs of our clients and providing them with the tools and support they need to thrive in a rapidly evolving digital landscape. </p>
//                 </div>
//               </div>
//             </div>

//             {/* Our Values Card */}
//             <div className="cards-wrappers">
//               <div className="cards">
//                 <div className="card-tops gradient-green">
//                   <div className="icon">
//                   <i className="fa fa-heart" style={{ color: '#363435' }}></i>

//                   </div>

//                 </div>
//                 <div className="card-content">
//                   <h4 className="card-title" style={{ paddingTop: '40px' }}>Our Values</h4>
//                 </div>
//                 <div className="overlay zoom">
//                   <h4 style={{ color: 'rgb(124 209 230)' }}>Our Values</h4>
//                   <p style={{ color: 'white', fontSize: '14px' }}>
//                     Innovation<br />
//                     Integrity<br />
//                     Collaboration<br />
//                     Customer-Centricity<br />
//                     Excellence<br />
//                     Sustainability<br />
//                     Adaptability
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </section>





//   );
// };

// export default OurCommitments;




import React from "react";


 import './OurCommitments.css'
import MissionValuesVisionCombined from '../img/banner/values.jpg';

import OurValuesHandPik from '../img/aaaservices/Vision.png';
import ourVisionCardRed1 from '../img/aaaservices/Mission.png';
import ourMissionCardRed2 from '../img/aaaservices/values.jpg';

const OurCommitments = () => {
  return (
    <div style={{  }} className="ournot-data">
      <div className="text-center">
      <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
                       Our <span style={{ color: "#3395AD" }}>Commitments</span>
                       <span style={{
                         position: 'absolute',
                         bottom: '-8px',
                         left: '50%',
                         transform: 'translateX(-50%)',
                         width: '50%',
                         height: '2px',
                         backgroundColor: '#000'
                       }} />
                     </h1>
      </div>
      <div className="wrapper">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-3" style={{margin:'20px 40px'}}>
            <div className="card">
              <img src={OurValuesHandPik} alt="Our Vision" />
              <div className="info" style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                justifyContent: 'center', height: '100%'
              }}>
                <h1>Our Vision</h1>
                <p style={{ fontSize: '14px' }}> Our vision is to be the premier provider of integrated technology solutions, driving innovation and transformation for businesses worldwide. We aspire to create a future where seamless technology integration accelerates business success and unlocks limitless potential.</p>
                
              </div>
            </div>
          </div>

          <div className="col-lg-3" style={{margin:'20px 40px'}}>
            <div className="card">
              <img src={ourVisionCardRed1} alt="Our Mission" />
              <div className="info" style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                justifyContent: 'center', height: '100%'
              }}>
                <h1>Our Mission</h1>
                <p>our mission is to empower organizations by delivering customized, cutting-edge technology solutions that drive growth, enhance efficiency, and foster innovation. We are committed to understanding the unique needs of our clients and providing them with the tools and support they need to thrive in a rapidly evolving digital landscape.</p>
               
              </div>
            </div>
          </div>

          <div className="col-lg-3" style={{margin:'20px 40px'}}>
            <div className="card">
              <img src={ourMissionCardRed2} alt="Our Values" />
              <div className="info" style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                justifyContent: 'center', height: '100%'
              }}>
                  <h1>Our Values</h1>
                   <p>Innovation
                     Integrity
                     Collaboration
                     Customer-Centricity
                     Excellence
                     Sustainability<br />
                     Adaptability</p>
               
              </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      
   
  );
};

export default OurCommitments;


// import React from "react";

// const MissionVisionValues = () => {
//   const containerStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "30px",
//     backgroundColor: "#f9f9f9",
//     padding: "40px 20px",
//     flexWrap: "wrap",
//   };

//   const cardStyle = {
//     position: "relative",
//     width: "100%",
//     maxWidth: "300px",
//     padding: "30px 20px",
//     borderRadius: "20px",
//     backgroundColor: "#fff",
//     boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//     textAlign: "center",
//     overflow: "hidden",
//     transition: "transform 0.3s ease, box-shadow 0.3s ease",
//   };

//   const headerStyle = (color) => ({
//     backgroundColor: color,
//     height: "50px",
//     borderTopLeftRadius: "20px",
//     borderTopRightRadius: "20px",
//   });

//   const iconStyle = {
//     fontSize: "40px",
//     margin: "20px 0",
//     transition: "transform 0.3s ease",
//   };

//   const cardHoverStyle = {
//     transform: "translateY(-10px)",
//     boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
//   };

//   const iconHoverStyle = {
//     transform: "translateY(-10px)",
//   };

//   const textHoverStyle = {
//     fontWeight: "bold", // Make the text bold on hover
//     fontSize: "24px",   // Increase the font size
//     transition: "all 0.3s ease", // Smooth transition for font change
//   };

//   // Text content for each card
//   const cardContent = [
//     {
//       title: "Mission",
//       iconClass: "fa-bullseye",
//       description: "Our mission is to deliver excellence in everything we do, focused on making a positive impact."
//     },
//     {
//       title: "Vision",
//       iconClass: "fa-lightbulb",
//       description: "Our vision is to innovate and inspire, transforming the future with creativity and purpose."
//     },
//     {
//       title: "Values",
//       iconClass: "fa-balance-scale",
//       description: "Our values are rooted in integrity, collaboration, and accountability, guiding us in all decisions."
//     },
//   ];

//   return (
//     <div>
//       <div className="text-center">
//         <h1
//           style={{
//             color: "#000",
//             fontSize: "48px",
//             display: "inline-block",
//             position: "relative",
//             fontFamily: "lato,sans,serif",
//             backgroundColor: "#f9f9f9",
//           }}
//         >
//           Our <span style={{ color: "#3395AD" }}>Commitments</span>
//           <span
//             style={{
//               position: "absolute",
//               bottom: "-8px",
//               left: "50%",
//               transform: "translateX(-50%)",
//               width: "50%",
//               height: "2px",
//               backgroundColor: "#000",
//             }}
//           />
//         </h1>
//       </div>

//       <div style={containerStyle}>
//         {cardContent.map((card, index) => (
//           <div
//             key={card.title}
//             style={{
//               position: "relative",
//               ...cardStyle,
//             }}
//             onMouseEnter={(e) => {
//               e.currentTarget.style.transform = cardHoverStyle.transform;
//               e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow;
//               e.currentTarget.querySelector("i").style.transform = iconHoverStyle.transform;
//               e.currentTarget.querySelector("h3").style = textHoverStyle; // Apply text hover effect
//             }}
//             onMouseLeave={(e) => {
//               e.currentTarget.style.transform = "none";
//               e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
//               e.currentTarget.querySelector("i").style.transform = "none";
//               e.currentTarget.querySelector("h3").style = {}; // Reset text style
//             }}
//           >
//             <div
//               style={headerStyle(
//                 index === 0 ? "black" : index === 1 ? "teal" : "black"
//               )}
//             ></div>
//             <i
//               style={iconStyle}
//               className={`fa ${card.iconClass}`}
//             ></i>
//             <h3>{card.title}</h3>
//             <p>{card.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default MissionVisionValues;








