import React, { useEffect, useState } from 'react';
import bannerImage from '../img/banner/out3.jpg';
import abouthome from '../img/banner/Hospitality1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import {
  faHome,
  faBuilding,
  faHospital,
  faTree,
  faStar,
  faEye,
} from '@fortawesome/free-solid-svg-icons';

import ServicesCards from './ServicesCards';
import Slicksider from "./Slicksider"
const Outdoor = () => {
  const [navbarHeight, setNavbarHeight] = useState(93); // Default navbar height
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []); // Empty dependency array ensures it only runs once when the component mounts
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };


  return (
    <div>
         <div>
        <div className="hero-area overly-style-1 opacity-point-4">
      {/* Replace video with image */}
      <img 
        className="banner-image" 
        src={bannerImage} 
        alt="About AA Applications Banner" 
        style={{ width: '100%', height: 'auto' }} 
      />
      
      <div className="hero-content-1 hero-content-position" style={{ color: '#fff' }}>
        <h1 style={{color:"#fff",fontSize:'48px'}}>OUTDOOR INTERIORS</h1>
        <div className="bread-subtitle" style={{marginTop:'0px'}}> Designing Extraordinary Spaces with Virtual Reality </div>

        {/* Uncomment these if needed */}
        {/* <h1 className="title" data-aos="fade-up" data-aos-delay="200" style={{ color: '#fff' }}>Co Working</h1> */}
        {/* <p data-aos="fade-up" data-aos-delay="300">Quisque at justo sagittis, semper lacus a, iaculis tellus. Fusce tempor, leo vel iaculis aliquet,</p> */}
        {/* <a href="project.html" className="btn btn-primary btn-hover-dark" data-aos="fade-up" data-aos-delay="400">VIEW PROJECT</a> */}
      </div>
    </div>
    </div>
    
 {/* Sticky Icon Slider */}
 <div>
        <div
          className="icon-slider-wrapper"
          style={{
            position: "sticky",
            top: `${navbarHeight}px`,
            backgroundColor: "#def2f7",
            zIndex: 1000,
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            height: "100px",
          }}
        >
          <Slider {...settings}>
            {/* Residential Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/it-services"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={ faHome} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>RESIDENTIAL INTERIORS</p>
              </a>
            </div>
            {/* Commercial Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/web-mobile-applications"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faBuilding} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>COMMERCIAL INTERIORS</p>
              </a>
            </div>
            {/* Hospitality Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/gaming"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faHospital} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>HOSPITALITY INTERIORS</p>
              </a>
            </div>
            {/* Showcase Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/snowcsc"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={ faTree} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>SHOWCASE INTERIORS</p>
              </a>
            </div>


            {/* Specialty Interiors */}
            <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/Specialty"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faStar} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>SPECIALTY INTERIORS</p>
              </a>
            </div>
          
 {/* Specialty Interiors */}
 <div style={{ textAlign: "center" }}>
              <a
                className="filter"
                onClick={() => window.location.href = "/outdoor"}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "33px",
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginBottom: "5px", fontSize: "24px" }} />
                <p>OUTDOOR INTERIOPRS</p>
              </a>
            </div>
          </Slider>
        </div>


        <section className="no-top no-bottom bggray" aria-label="abouthome">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 p-0">
        <img src={abouthome}className="imgbg-col" alt="imghome" />
          </div>
          <div className="col-md-6 centered">
            <div className="detailcontent">
              <div className="subheading" style={{ color: '#1c1d1d', fontSize: '48px', textAlign: 'center', marginBottom: '11px' }}>
              <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative' }}>
                      OUTDOOR <span style={{ color: "#3395AD" }}>INTERIORS</span>
                      <span style={{
                        position: 'absolute',
                        bottom: '-8px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '50%',
                        height: '2px',
                        backgroundColor: '#000'
                      }} />
                    </h1>
              </div>
              {/* <div className="heading">Best Interior is Our Passion</div> */}
              <div className="textdetail">
              <p style={{ fontSize: '16px', textAlign: 'justify' }}>
              <span style={{fontFamily: 'Raqupine Regular'}}> MODULEX </span>  Outdoor and Landscape Interiors transform exterior spaces into extensions of your living area. Our designs include Stylish Patios with comfortable seating and durable materials for relaxation and entertaining, Elegant Pool Areas featuring luxurious pools, lounging spaces, and outdoor kitchens, and Beautiful Garden Spaces with thoughtfully designed landscaping, pathways, and lighting to create serene environments. 
           
.
               </p>
               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
            . </p>
               <p style={{ fontSize: '16px', textAlign: 'justify' }}>
               <span style={{fontFamily: 'Raqupine Regular' }}> MODULEX </span> We integrate Custom Features like built-in fire pits, outdoor fireplaces, and pergolas to enhance functionality and aesthetics. Each project is tailored to complement your home's style while maximizing outdoor enjoyment and usability.  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <ServicesCards/> */}
    </div>
    </div>
  )
}

export default Outdoor




// import React from "react";
// import residentialImage from "../img/banner/Hospitality1.png"; // Replace with actual relative path
// import outdoorImage from "../img/banner/out3.jpg"; // Replace with actual relative path

// const OutdoorEnvironments = () => {
//   return (
//     <>
//       {/* Breadcrumb Section */}
//       <section
//         className="breadcumb"
//         aria-label="breadcumb"
//         style={{
//           backgroundImage: `url(${outdoorImage})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//         }}
//       >
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-12">
//               <div className="main">
//                 <div className="bread">
//                   <div className="bread-title">Outdoor Environments</div>
//                   <div className="bread-subtitle">
//                     <span>Designing Inspiring Outdoor Environments through VR.</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* About Section */}
//       <section className="no-top no-bottom bggray" aria-label="abouthome">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="col-md-6 p-0">
//               <img
//                 src={residentialImage}
//                 className="imgbg-col"
//                 alt="Residential Collage"
//               />
//             </div>
//             <div className="col-md-6 centered">
//               <div className="detailcontent">
//                 <div className="subheading">
//                   <h1 style={{ textAlign: "center", marginBottom: "15px" }}>
//                     OUTDOOR ENVIRONMENTS
//                   </h1>
//                 </div>
//                 <div className="textdetail">
//                   Outdoor and Landscape Interiors transform exterior spaces into
//                   extensions of your living area. Our designs include Stylish Patios
//                   with comfortable seating and durable materials for relaxation and
//                   entertaining, Elegant Pool Areas featuring luxurious pools,
//                   lounging spaces, and outdoor kitchens, and Beautiful Garden
//                   Spaces with thoughtfully designed landscaping, pathways, and
//                   lighting to create serene environments. We integrate Custom
//                   Features like built-in fire pits, outdoor fireplaces, and pergolas
//                   to enhance functionality and aesthetics. Each project is tailored
//                   to complement your home's style while maximizing outdoor enjoyment
//                   and usability.
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Pricing Section */}
//       <section className="pricing-area ptb-100">
//         <div className="container">
//           <div className="section-title" style={{ textAlign: "center" }}>
//             <h2>OUR PRICING PACKAGES</h2>
//           </div>
//           <div className="row">
//             {/* Pricing Card 1 */}
//             <div className="col-lg-4 col-md-6 col-sm-6">
//               <div className="single-pricing-box">
//                 <div className="pricing-header bg18">
//                   <h3>Patios and Decks</h3>
//                 </div>
//                 <div className="price">
//                   <sub>₹</sub>179<sub>/mo</sub>
//                 </div>
//                 <div className="book-now-btn">
//                   <a href="contact.php" className="default-btn">
//                     Book Now <span></span>
//                   </a>
//                 </div>
//                 <ul className="pricing-features-list">
//                   <li>Durable Flooring</li>
//                   <li>Comfortable Seating</li>
//                   <li>Outdoor Kitchen</li>
//                   <li>Shade Structures</li>
//                   <li>Ambient Lighting</li>
//                 </ul>
//               </div>
//             </div>
//             {/* Pricing Card 2 */}
//             <div className="col-lg-4 col-md-6 col-sm-6">
//               <div className="single-pricing-box">
//                 <div className="pricing-header bg19">
//                   <h3>Garden Landscaping</h3>
//                 </div>
//                 <div className="price">
//                   <sub>₹</sub>295<sub>/mo</sub>
//                 </div>
//                 <div className="book-now-btn">
//                   <a href="contact.php" className="default-btn">
//                     Book Now <span></span>
//                   </a>
//                 </div>
//                 <ul className="pricing-features-list">
//                   <li>Elegant Pool Design</li>
//                   <li>Comfortable Lounging</li>
//                   <li>Outdoor Kitchen and Bar</li>
//                   <li>Safety Features</li>
//                   <li>Landscaping and Lighting</li>
//                 </ul>
//               </div>
//             </div>
//             {/* Pricing Card 3 */}
//             <div className="col-lg-4 col-md-6 col-sm-6">
//               <div className="single-pricing-box">
//                 <div className="pricing-header bg20">
//                   <h3>Outdoor Living Rooms</h3>
//                 </div>
//                 <div className="price">
//                   <sub>₹</sub>139<sub>/mo</sub>
//                 </div>
//                 <div className="book-now-btn">
//                   <a href="contact.php" className="default-btn">
//                     Book Now <span></span>
//                   </a>
//                 </div>
//                 <ul className="pricing-features-list">
//                   <li>Weather-Resistant Furniture</li>
//                   <li>
//                     <span style={{ textDecoration: "line-through" }}>
//                       Outdoor Heating
//                     </span>
//                   </li>
//                   <li>Integrated Lighting</li>
//                   <li>Entertainment Systems</li>
//                   <li>
//                     <span style={{ textDecoration: "line-through" }}>
//                       Shade Structures
//                     </span>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default OutdoorEnvironments;
