


// import React, { useEffect } from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

// // Importing images
// import img1 from '../img/testimony/test1.jpg';
// import img2 from '../img/testimony/test2.jpg';
// import img3 from '../img/testimony/test3.jpg';

// const Testimonials = () => {
//   // Owl Carousel settings
//   const options = {
//     loop: true,
//     autoplay: true,
//     autoplayTimeout: 3000,
//     smartSpeed: 800,
//     dots: true,
//     nav: false,
//     margin: 20,
//     responsive: {
//       0: { items: 1 },
//       600: { items: 2 },
//       1000: { items: 3 },
//     },
//   };

//   return (
//     <section aria-label="testimony">
//       <div className="container-fluid">
//         <div className="row p-3-vh">
//           <div className="col-12 centered">
//             <div className="detailcontent text-center p-0">
//               <div className="heading" style={{ color: '#dabd33' }}>
//                 What <span style={{ color: '#0a8194' }}>People Say</span>
//               </div>
//             </div>
//           </div>
//           <div className="col-12">
//             <OwlCarousel className="owl-carousel owl-theme" {...options}>
//               <div className="item">
//                 <div className="content-testy">
//                   <div className="testy-text">
//                     <span>
//                       You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
//                     </span>
//                   </div>
//                   <div className="user">
//                     <div className="img">
//                       <img src={img1} alt="imgtesty" />
//                     </div>
//                     <div className="name">Paul Rombes</div>
//                     <div className="title">Our Client</div>
//                   </div>
//                 </div>
//               </div>

//               <div className="item">
//                 <div className="content-testy">
//                   <div className="testy-text">
//                     <span>
//                       You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
//                     </span>
//                   </div>
//                   <div className="user">
//                     <div className="img">
//                       <img src={img2} alt="imgtesty" />
//                     </div>
//                     <div className="name">Bill Clapton</div>
//                     <div className="title">Our Client</div>
//                   </div>
//                 </div>
//               </div>

//               <div className="item">
//                 <div className="content-testy">
//                   <div className="testy-text">
//                     <span>
//                       You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
//                     </span>
//                   </div>
//                   <div className="user">
//                     <div className="img">
//                       <img src={img3} alt="imgtesty" />
//                     </div>
//                     <div className="name">Jesica James</div>
//                     <div className="title">Our Client</div>
//                   </div>
//                 </div>
//               </div>

//               <div className="item">
//                 <div className="content-testy">
//                   <div className="testy-text">
//                     <span>
//                       You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
//                     </span>
//                   </div>
//                   <div className="user">
//                     <div className="img">
//                       <img src={img1} alt="imgtesty" />
//                     </div>
//                     <div className="name">Paul Rombes</div>
//                     <div className="title">Our Client</div>
//                   </div>
//                 </div>
//               </div>

//               <div className="item">
//                 <div className="content-testy">
//                   <div className="testy-text">
//                     <span>
//                       You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
//                     </span>
//                   </div>
//                   <div className="user">
//                     <div className="img">
//                       <img src={img2} alt="imgtesty" />
//                     </div>
//                     <div className="name">Bill Clapton</div>
//                     <div className="title">Our Client</div>
//                   </div>
//                 </div>
//               </div>

//               <div className="item">
//                 <div className="content-testy">
//                   <div className="testy-text">
//                     <span>
//                       You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
//                     </span>
//                   </div>
//                   <div className="user">
//                     <div className="img">
//                       <img src={img3} alt="imgtesty" />
//                     </div>
//                     <div className="name">Jesica James</div>
//                     <div className="title">Our Client</div>
//                   </div>
//                 </div>
//               </div>
//             </OwlCarousel>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Testimonials;




import React, { useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Importing images
import img1 from '../img/testimony/test1.jpg';
 import img2 from '../img/testimony/test2.jpg';
 import img3 from '../img/testimony/test3.jpg';

const Testimonials = () => {
  // Owl Carousel settings
  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 800,
    dots: true,
    nav: false,
    margin: 20,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  return (
    <section aria-label="testimony" style={{margin:'auto',}}>
      <div className="container-fluid">
        <div className="row p-3-vh">
          <div className="col-12 centered">
            <div className="detailcontent text-center p-0">
              <div className="heading" style={{ color: 'rgb(16 16 14);' }}>
                What <span style={{ color: '#0a8194',fontFamily:'lato,sans,serif' }}>People Say</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <OwlCarousel className="owl-carousel owl-theme" {...options}>
              <div className="item">
                <div className="content-testy" style={{ border: '2px solid #0a8194', padding: '20px', borderRadius: '10px' }}>
                  <div className="testy-text">
                    <span>
                      You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
                    </span>
                  </div>
                  <div className="user">
                    <div className="img">
                      <img src={img1} alt="imgtesty" />
                    </div>
                    <div className="name">Paul Rombes</div>
                    <div className="title">Our Client</div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="content-testy" style={{ border: '2px solid #0a8194', padding: '20px', borderRadius: '10px' }}>
                  <div className="testy-text">
                    <span>
                      You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
                    </span>
                  </div>
                  <div className="user">
                    <div className="img">
                      <img src={img2} alt="imgtesty" />
                    </div>
                    <div className="name">Bill Clapton</div>
                    <div className="title">Our Client</div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="content-testy" style={{ border: '2px solid #0a8194', padding: '20px', borderRadius: '10px' }}>
                  <div className="testy-text">
                    <span>
                      You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
                    </span>
                  </div>
                  <div className="user">
                    <div className="img">
                      <img src={img3} alt="imgtesty" />
                    </div>
                    <div className="name">Jesica James</div>
                    <div className="title">Our Client</div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="content-testy" style={{ border: '2px solid #0a8194', padding: '20px', borderRadius: '10px' }}>
                  <div className="testy-text">
                    <span>
                      You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
                    </span>
                  </div>
                  <div className="user">
                    <div className="img">
                      <img src={img1} alt="imgtesty" />
                    </div>
                    <div className="name">Paul Rombes</div>
                    <div className="title">Our Client</div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="content-testy" style={{ border: '2px solid #0a8194', padding: '20px', borderRadius: '10px' }}>
                  <div className="testy-text">
                    <span>
                      You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
                    </span>
                  </div>
                  <div className="user">
                    <div className="img">
                      <img src={img2} alt="imgtesty" />
                    </div>
                    <div className="name">Bill Clapton</div>
                    <div className="title">Our Client</div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="content-testy" style={{ border: '2px solid #0a8194', padding: '20px', borderRadius: '10px' }}>
                  <div className="testy-text">
                    <span>
                      You will never fake the feeling of being in such a place. The live minimalism base on the natural materials & alive unprocessed.
                    </span>
                  </div>
                  <div className="user">
                    <div className="img">
                      <img src={img3} alt="imgtesty" />
                    </div>
                    <div className="name">Jesica James</div>
                    <div className="title">Our Client</div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;



