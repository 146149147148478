import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
// import logo from '../img/logo/IIA_LOGO_White_v3.png';
// import logo1 from '../img/logo/IIA_LOGO_White_v3.png';
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const [navbarBackground, setNavbarBackground] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // For toggle
  const location = useLocation(); // Get current location
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // State for dropdown suggestions
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setNavbarBackground(true);
      } else {
        setNavbarBackground(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // Update search query and filter suggestions
 
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    if (searchQuery.includes("mini")) {
      navigate("/web-mobile-applications"); // Navigate to the mini page
    } else if (searchQuery.includes("medium")) {
      navigate("/it-services"); // Navigate to the medium page
    } else if (searchQuery.includes("large")) {
      navigate("/gaming"); // Navigate to the large page
      
    } else {
      alert("No matching page for your search query.");
    }
  };

  const pages = [

    { label: "Mini", path: "/web-mobile-application" },
    { label: "virtual robotics", path: "/Auto" },
    { label: "digital facility solutions", path: "/Ofrobo" },
    { label: "Front End ", path: "/Front1" },
    { label: "Back End ", path: "/Backend" },
    { label: "Testing", path: "/Testing" },
    { label: "Data Engineer", path: "/dataE" },
    { label: "Data Scince", path: "/Data" },
    { label: "Data Analystics", path: "/DataA" },
  ];

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion.label); // Update input value
    navigate(suggestion.path); // Navigate to selected page
    setFilteredSuggestions([]); // Clear suggestions
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Toggle sidebar state
  };

  return (
    <>
      {/* Navbar Header */}
      <header className="init">
        <div className={`navbar-default-white navbar-fixed-top ${navbarBackground ? 'navbar-scrolled' : ''}`}>
          <div className="container-fluid">
            <div className="row p-3-vh align-items-center">
              {/* Logo and Text */}
              <Link className="logo centered d-flex align-items-center" to="/">
                {/* <img className="h-150 init" alt="logo" src={logo} />
                <img className="h-150 show" alt="logo" src={logo1} /> */}
                <br></br><br></br><br></br><br></br>
                <div className="ml-3 text-white">
                  <Link to="/" className="text-white text-decoration-none">
                    <h1 className="m-0 fontsmall" style={{ fontFamily: 'Raqupine Regular' }}>TRANS2go</h1>
                    <p className="m-0 fontmini"style={{ fontFamily: 'Raqupine Regular'}} >LOGISTIC</p>
                  </Link>
                </div>

              </Link>
              {/* Main Menu */}
              <div
                className="menu-container navdatac"
                style={{
                  display: "flex",
                  justifyContent: "space-between", // Space out sections evenly
                  alignItems: "center", // Vertically align items
                  padding: "0 5px", // Add padding for spacing
                  flexWrap: "wrap", // Allow items to wrap for smaller screens
                }}
              >

                <div
                  className="search-bar"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    borderRadius: "13px",
                    padding: "5px 0px",
                    position: "relative",
                    backgroundColor: "white",
                    maxWidth: "27%",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search Here "
                    style={{
                      border: "none",
                      outline: "none",
                      padding: "5px 10px",
                      width: "80%",
                    }}
                    value={searchQuery} // Bind input value
                    onChange={handleInputChange} // Update state on input change
                  />


                  
                  <button
                    onClick={handleSearch} // Call handleSearch on click
                    style={{
                      backgroundColor: "#000",
                      color: "white",
                      border: "none",
                      padding: "8px 15px",
                      borderRadius: "21px",
                      cursor: "pointer",
                      marginLeft: "10px", // Adjust margin various codes all other 
                    }}
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>

                {/* Navigation Links */}
                <ul
                  style={{
                    display: "flex",
                    listStyle: "none",
                    margin: "0",
                    padding: "0",
                    flex: "1", // Allow navigation to grow and fill available space
                    justifyContent: "center", // Center navigation links
                  }}
                >
                  <li>
                    <Link
                      to="/"
                      className={`nav-link ${location.pathname === '/' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px", fontWeight: 'bold' }}
                    >
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/who-we-are"
                      className={`nav-link ${location.pathname === '/who-we-are' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px", fontWeight: 'bold' }}
                    >
                      WHO WE ARE
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/what-we-do"
                      className={`nav-link ${location.pathname === '/what-we-do' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px", fontWeight: 'bold' }}
                    >
                      THE LOGISTIC
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/contact"
                      className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
                      style={{ margin: "0 10px", fontWeight: 'bold' }}
                    >
                      CONTACT
                    </Link>
                  </li>
                </ul>

                {/* Social Media Links */}
                <div
                  className="social-icons"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "32px", // Space between icons
                  }}
                >
                  <Link to="https://x.com/applications_it" target="_blank">
                    <span className="ti-twitter" style={{ fontSize: "20px" }}></span>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/feed/?trk=onboarding-landing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="ti-linkedin" style={{ fontSize: "20px" }}></span>
                  </Link>
                  <Link to="https://www.instagram.com/appli_cation123/" target="_blank">
                    <span className="ti-instagram" style={{ fontSize: "20px" }}></span>
                  </Link>
                  <Link to="https://www.youtube.com/feed/you" target="_blank">
                    <span className="ti-youtube" style={{ fontSize: "20px" }}></span>
                  </Link>
                </div>
              </div>


              {/* Sidebar Toggle Button for Large Screens */}
              <div className="menu-right centered d-none d-md-block">
                <ul className="iconright">
                  <li>
                    <div onClick={toggleSidebar} className="toggle-button">
                      <span className="ti-menu"></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Sidebar for Large Screens */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <div
            className="search-bar"
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: "30px",
              padding: "5px 15px",
              position: "relative",
              backgroundColor: "white",
              // maxWidth: "27%",
              width: "100%",
              marginTop: '20px'
            }}
          >
            <input
              type="text"
              placeholder="Search What You Want"
              style={{
                border: "none",
                outline: "none",
                padding: "5px 10px",
                width: "80%",
              }}
              value={searchQuery} // Bind input value
              onChange={handleInputChange} // Update state on input change
            />
            <button
              onClick={handleSearch} // Call handleSearch on click
              style={{
                backgroundColor: "#000",
                color: "white",
                border: "none",
                padding: "8px 15px",
                borderRadius: "10px",
                cursor: "pointer",
                marginLeft: "10px", // Adjust margin
              }}
            >
              Search
            </button>
          </div>
          <ul>
            <li>
              <Link
                to="/"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/' ? 'active-link' : ''}`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/who-we-are"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click 
                className={`nav-link ${location.pathname === '/who-we-are' ? 'active-link' : ''}`}
              >
                Who we are
              </Link>
            </li>
            <li>
              <Link
                to="/what-we-do"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click
                className={`nav-link ${location.pathname === '/what-we-do' ? 'active-link' : ''}`}
              >
                The Logistic
              </Link>
            </li>


            <li>
              <Link
                to="/contact"
                onClick={() => setIsSidebarOpen(false)} // Close sidebar on link click 
                className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Sidebar and Active Link CSS */}
      <style jsx>{`
         .sidebar {
          position: fixed;
          top: 0;
          right: -250px; /* Start off-screen */
          width: 250px;
          height: 100%;
          background-color: #313840;
          transition: right 0.3s ease-in-out; /* Smooth transition */
          z-index: 1000; /* Ensure it stays on top */
        }

        .sidebar.open {
          right: 0; /* Slide in */
          width:100%;
        }

        .sidebar-content ul {
          padding: 0;
          list-style: none;
          margin: 0;
        }

        .sidebar-content ul li {
          padding: 15px 20px;
          border-bottom: 1px solid #444;
        }

        .sidebar-content ul li a {
          color: white;
          text-decoration: none;
        }

        .toggle-button {
          cursor: pointer;
        }

        .dropdown-toggle {
          background: none;
          border: none;
          color: white;
          padding: 10px;
          cursor: pointer;
          width: 100%;
          text-align: left;
        }

        .dropdown-menu {
          list-style: none;
          padding: 0;
          margin: 0;
          background-color: #444;
        }

        // .dropdown-menu li {
        //   padding: 10px 20px;
        // }

        .dropdown-menu li a {
          color: white;
          text-decoration: none;
        }

        .dropdown-menu li a:hover {
          color: #f76046;
        }

        .active-link {
          font-weight: bold;
          // color: #f76046;
        }

        /* Show toggle button and sidebar on larger screens */
        @media (min-width: 1200px) {
          .sidebar {
            display: none; /* Hide sidebar */
          }

          .toggle-button {
            display: block; /* Show toggle button */
          }

          /* Dropdown for mobile */
          .dropdown-toggle {
            display: none; /* Hide dropdown button on larger screens */
          }
        }

        /* For smaller screens (mobile view) */
        @media (max-width: 767px) {
          .sidebar-content ul li {
            padding: 10px 20px;
          }

          .dropdown-menu {
            display: block;
            background-color: #313840;
            border-top: 1px solid #313840;
          }

          .dropdown-menu li a {
            padding-left: 40px; /* Indent dropdown items */
          }

          .sidebar-content ul li .dropdown-menu li {
            padding: 10px;
          }
        }
      `}</style>
    </>
  );
};

export default Nav;
