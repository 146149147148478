


// import React from "react";
// import Slider from "react-slick";

// // Import images
// import picture1 from "../img/banner/logo10.png";
// import asm from "../img/banner/Picture2.png";
// import group from "../img/banner/Picture1.jpg";

// const Ourclints = () => {
//   const logoSliderss = {
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     arrows: false,
//     autoplay: true,
//     infinite: true,
//     autoplaySpeed: 2000,
//     dots: false,
//     responsive: [
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 900,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           dots: false,
//           arrows: false,
//           centerMode: false,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           dots: false,
//           arrows: false,
//           centerMode: false,
//         },
//       },
//     ],
//   };

//   return (
//     <div>
//       <div className="container-fluid" style={{ paddingBottom: "20px",backgroundColor:'#fff',height:'220px'  }}>
//         {/* <div className="container"> */}
//           <div className="row text-center">
//             {/* Centered Heading */}
//             <div className="col-12">
//             <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative',fontFamily:'lato,sans,serif' }}>
//                        Our <span style={{ color: "#3395AD" }}>Client</span>
//                        <span style={{
//                          position: 'absolute',
//                          bottom: '-8px',
//                          left: '50%',
//                          transform: 'translateX(-50%)',
//                          width: '50%',
//                          height: '2px',
//                          backgroundColor: '#000'
//                        }} />
//                      </h1>
//             </div>
//           </div>

//          {/* Image Slider Section */}
// <div className="row">
//   <div className="col-12">
//     <Slider {...logoSliderss}>
//       {/* Image 1 */}
//       <div className="single-partner" style={{ padding: "0 5px" }}>
//         <img
//           src={picture1}
//           alt="Client Logo 1"
//           style={{
//             height: "65px",
           
//             margin: "0 auto", // Center image
//             display: "block",
//             objectFit: "contain", // Ensure the image fits within the bounds
//           }}
//         />
//       </div>

//       {/* Image 2 */}
//       <div className="single-partner" style={{ padding: "" }}>
//         <img
//           src={asm}
//           alt="Client Logo 2"
//           style={{
//             height: "65px",
           
//             margin: "0 auto", // Center image
//             display: "block",
//             objectFit: "contain", // Ensure the image fits within the bounds
//           }}
//         />
//       </div>

//       {/* Image 3 */}
//       <div className="single-partner" style={{ padding: "" }}>
//         <img
//           src={group}
//           alt="Client Logo 3"
//           style={{
//             height: "65px",
           
//             margin: "0 auto", // Center image
//             display: "block",
//             objectFit: "contain", // Ensure the image fits within the bounds
//           }}
//         />
//       </div>
//     </Slider>
//   </div>
// </div>

              
//             </div>
//           </div>
       
      
   
//   );
// };

// export default Ourclints;





// import React from "react";
// import Slider from "react-slick";

// // Import images
// import picture1 from "../img/banner/logo10.png";
// import asm from "../img/banner/Picture2.png";
// import group from "../img/banner/Picture1.jpg";

// const Ourclints = () => {
//   const logoSliderss = {
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     arrows: false,
//     autoplay: true,
//     infinite: true,
//     autoplaySpeed: 3000, // Slower sliding effect
//     speed: 1000, // Smooth transition time for each slide
//     dots: false,
//     centerMode: true, // Centers the images in the view
//     focusOnSelect: true,
//     responsive: [
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 900,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           dots: false,
//           arrows: false,
//           centerMode: false,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           dots: false,
//           arrows: false,
//           centerMode: false,
//         },
//       },
//     ],
//   };

//   return (
//     <div>
//       <div className="container-fluid" style={{ paddingBottom: "20px", backgroundColor: '#fff', height: '220px' }}>
//         <div className="row text-center">
//           <div className="col-12">
//             <h1 style={{ color: "#000", fontSize: '48px', display: 'inline-block', position: 'relative', fontFamily: 'lato,sans,serif' }}>
//               Our <span style={{ color: "#3395AD" }}>Client</span>
//               <span style={{
//                 position: 'absolute',
//                 bottom: '-8px',
//                 left: '50%',
//                 transform: 'translateX(-50%)',
//                 width: '50%',
//                 height: '2px',
//                 backgroundColor: '#000'
//               }} />
//             </h1>
//           </div>
//         </div>

//         {/* Image Slider Section */}
//         <div className="row">
//           <div className="col-12">
//             <Slider {...logoSliderss}>
//               {/* Image 1 */}
//               <div className="single-partner" style={{ padding: "0", margin: "0" }}>
//                 <div className="image-box" style={{ width: '394px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5', margin: "0 10px" }}>
//                   <img
//                     src={picture1}
//                     alt="Client Logo 1"
//                     style={{
//                       height: "80px",
                     
//                       objectFit: "cover", // Ensure the image covers the box area
//                     }}
//                   />
//                 </div>
//               </div>

//               {/* Image 2 */}
//               <div className="single-partner" style={{ padding: "0", margin: "0" }}>
//                 <div className="image-box" style={{ width: '394px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5', margin: "0 10px" }}>
//                   <img
//                     src={asm}
//                     alt="Client Logo 2"
//                     style={{
//                       height: "80px",
                     
//                       objectFit: "cover", // Ensure the image covers the box area
//                     }}
//                   />
//                 </div>
//               </div>

//               {/* Image 3 */}
//               <div className="single-partner" style={{ padding: "0", margin: "0" }}>
//                 <div className="image-box" style={{ width: '394px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5', margin: "0 10px" }}>
//                   <img
//                     src={group}
//                     alt="Client Logo 3"
//                     style={{
//                       height: "80px",
                     
//                       objectFit: "cover", // Ensure the image covers the box area
//                     }}
//                   />
//                 </div>
//               </div>
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Ourclints;



// import React from "react";
// import Slider from "react-slick";

// // Import images
// import picture1 from "../img/banner/logo10.png";
// import asm from "../img/banner/Picture2.png";
// import group from "../img/aaaservices/Picture1-removebg-preview (1).png";

// const Ourclints = () => {
//   const logoSliderss = {
//     slidesToShow: 3,
//     slidesToScroll: 1, // Continuous sliding effect
//     arrows: false,
//     autoplay: true,
//     infinite: true,
//     autoplaySpeed: 3000,
//     speed: 1000,
//     dots: false,
//     centerMode: false, // Disable centering for seamless slide
//     focusOnSelect: true,
//     responsive: [
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: false,
//         },
//       },
//       {
//         breakpoint: 900,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           dots: false,
//           arrows: false,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           dots: false,
//           arrows: false,
//         },
//       },
//     ],
//   };

//   return (
//     <div>
//       <div className="container-fluid" style={{ paddingBottom: "20px", backgroundColor: "#fff", height: "220px" }}>
//         <div className="row text-center">
//           <div className="col-12">
//             <h1
//               style={{
//                 color: "#000",
//                 fontSize: "48px",
//                 display: "inline-block",
//                 position: "relative",
//                 fontFamily: "lato,sans,serif",
//               }}
//             >
//               Our <span style={{ color: "#3395AD" }}>Client</span>
//               <span
//                 style={{
//                   position: "absolute",
//                   bottom: "-8px",
//                   left: "50%",
//                   transform: "translateX(-50%)",
//                   width: "50%",
//                   height: "2px",
//                   backgroundColor: "#000",
//                 }}
//               />
//             </h1>
//           </div>
//         </div>

//         {/* Image Slider Section */}
//         <div className="row">
//           <div className="col-12">
//             <Slider {...logoSliderss}>
//               {[picture1, asm, group].map((imgSrc, index) => (
//                 <div key={index} className="single-partner" style={{ padding: "20", margin: "20" }}>
//                   <div
//                     className="image-box"
//                     style={{
//                       width: "100%",
//                       height: "100px",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       backgroundColor: "#f5f5f5",
//                       // borderRadius: "10px",
//                       border: "2px solid #3395AD", // Border around the box
//                     }}
//                   >
//                     <img
//                       src={imgSrc}
//                       alt={`Client Logo ${index + 1}`}
//                       style={{
//                         width: "96%",
//                         height: "75%",
//                         objectFit: "contain",
//                         padding: "0",
//                       }}
//                     />
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Ourclints;












// import React from "react";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
// import picture1 from "../img/Picture1-removebg-preview (1).png";
// // import picture1 from "../img/logo/Picture1.jpg";
// import picture2 from "../img/logo/logo10.png";

// const OurCoPartneres = () => {
//     return (
//         <div>
//             <div style={{ background: "white" }}>
//                 <div
//                     className="section-full  content-inner-1 what-we-do overlay-black-dark bg-img-fix"
//                     id="services"
//                 >
//                     <div className="container">
//                         <div className="section-head text-center text-white">
//                             <h1 style={{ fontSize: "48px", lineHeight: "1.3",color:'black' }} className='title-data'>
//                                 Our <span style={{ color: "#F58634" }}>Partners</span>
//                             </h1>
//                             {/* <h2 className="text-uppercase" style={{ color: "#544395", }}>Our <span style={{ color: " #F58634" }}>Partners</span></h2> */}
//                             {/* <div className="dlab-separator bg-primary"></div> */}
//                         </div>
//                         <div className="section-content">
//                             <div className="container mb-5">
//                                 <div className="row">
//                                     <div className="col-lg-6 ow-event-post" >
//                                         <div className="ow-event-info conheight " style={{ borderLeft: '1px solid #eae9f9', borderTop: '1px solid #eae9f9',margin:'0px 40px',borderRadius:'20px',boxShadow:'7px 7px 7px #c3c3d3',backgroundColor:'#3a3b40' }}>
//                                             <div
//                                                 style={{ padding: "16px", textAlign: "center" }}
//                                             >
//                                                 <img
//                                                     src={picture1}
//                                                     alt=""
//                                                     style={{ width: "210px", height: "100px" }}
//                                                 />
//                                             </div>

//                                         </div>
//                                     </div>
//                                     <div className="col-lg-6 ow-event-post" >
//                                         <div className="ow-event-info conheight " style={{ borderLeft: '1px solid #eae9f9', borderTop: '1px solid #eae9f9',margin:'0px 40px',borderRadius:'20px',boxShadow:'7px 7px 7px #c3c3d3',backgroundColor:'#3a3b40'   }}>
//                                             <div
//                                                 style={{ padding: "16px", textAlign: "center" }}
//                                             >
//                                                 <img
//                                                     src={picture2}
//                                                     alt=""
//                                                     style={{ width: "210px", height: "100px" }}
//                                                 />
//                                             </div>

//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default OurCoPartneres;




import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import picture1 from "../img/Picture1-removebg-preview (1).png";
import picture2 from "../img/logo/logo10.png";
import picture3 from "../img/logo/Picture2.png"; // Add your third image here

const OurCoPartners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Adjust for larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div style={{ background: "white" }}>
      <div className="section-full content-inner-1 what-we-do overlay-black-dark bg-img-fix" id="services" style={{height:'300px',}}>
        <div className="container">
          <div className="section-head text-center text-white">
            <h1 style={{ fontSize: "48px", lineHeight: "1.3", color: 'black',margin:'auto',marginBottom:'44px', }} className='title-data'>
              Our <span style={{ color: "#3395AD" }}>Client</span>
            </h1>
          </div>
          <div className="section-content">
            <div className="container mb-5">
              <Slider {...settings}>
                <div className="ow-event-post">
                  <div className="ow-event-info conheight" style={boxStyle}>
                    <div style={{ padding: "16px", textAlign: "center" }}>
                      <img src={picture1} alt="Partner 1" style={imageStyle} />
                    </div>
                  </div>
                </div>
                <div className="ow-event-post">
                  <div className="ow-event-info conheight" style={boxStyle}>
                    <div style={{ padding: "16px", textAlign: "center" }}>
                      <img src={picture2} alt="Partner 2" style={imageStyle} />
                    </div>
                  </div>
                </div>
                <div className="ow-event-post">
                  <div className="ow-event-info conheight" style={boxStyle}>
                    <div style={{ padding: "16px", textAlign: "center" }}>
                      <img src={picture3} alt="Partner 3" style={imageStyle} />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const boxStyle = {
  borderLeft: '1px solid #eae9f9',
  borderTop: '1px solid #eae9f9',
  margin: '0px 40px',
  borderRadius: '20px',
  boxShadow: '7px 7px 7px #c3c3d3',
  backgroundColor: '#ffff',
};

const imageStyle = {
  width: "210px",
  height: "100px",
};

export default OurCoPartners;





