import React from "react";
import Slider from "react-slick";
import residentialImg from "../img/aaaservices/miduam ss.jpg";
import cmImg from "../img/aaaservices/MINI3.jpg";
import hospitalityImg from "../img/aaaservices/MIDUAM2.webp";
import sshImg from "../img/aaaservices/BHART4.png";
import specialtyImg from "../img/aaaservices/motor2.jpg";
import galleryImg6 from "../img/aaaservices/man.jpg";
import galleryImg7 from "../img/aaaservices/BHART.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GallerySlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true, // Disable next/prev arrows
  };

  return (
    <section style={{ marginTop: "5px", backgroundColor: "#dae2e3" }}>
      <div className="container-fluid" style={{ padding: "0px", overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-12">
            <Slider {...settings}>
              <div className="item">
                <img
                  src={residentialImg}
                  alt="Residential Interiors"
                  style={{ height: "700px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={cmImg}
                  alt="Commercial Interiors"
                  style={{ height: "700px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={hospitalityImg}
                  alt="Hospitality Interiors"
                  style={{ height: "700px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={sshImg}
                  alt="SSH Interiors"
                  style={{ height: "700px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={specialtyImg}
                  alt="Specialty Interiors"
                  style={{ height: "700px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={galleryImg6}
                  alt="Gallery Image 6"
                  style={{ height: "700px", width: "100%" }}
                />
              </div>
              <div className="item">
                <img
                  src={galleryImg7}
                  alt="Gallery Image 7"
                  style={{ height: "700px", width: "100%" }}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySlider;

