import React, { useState } from "react";

import galleryImg1 from "../img/3.jpg";
import galleryImg2 from "../img/11.jpg";
import galleryImg3 from "../img/3.jpg";
import galleryImg4 from "../img/11.jpg";
import galleryImg5 from "../img/5.jpg";
import galleryImg6 from "../img/1.jpg";
import galleryImg7 from "../img/7.jpg";
import galleryImg8 from "../img/8.jpg";
import galleryImg9 from "../img/9.jpg";
import galleryImg10 from "../img/10.jpg";

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const openModal = (imgSrc, alt) => {
    setCurrentImage({ imgSrc, alt });
    setIsOpen(true);
  };

  const closeModal = () => {
    setCurrentImage(null);
    setIsOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to server)
    console.log(formData);
  };

  const styles = {
    galleryArea: {
      padding: "20px",
      display: "flex",
      flexWrap: "wrap",
      gap: "10px",
      justifyContent: "center",
    },
    imageContainer: {
      cursor: "pointer",
      marginBottom: "10px",
    },
    image: {
      width: "100%",
      height: "auto",
      borderRadius: "5px",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      position: "relative",
      background: "#fff",
      padding: "20px",
      borderRadius: "8px",
      maxWidth: "80%", // Increase the width of the modal
      maxHeight: "80%",
      display: "flex", // Make the modal a flex container
      flexDirection: "row", // Display items side by side
      justifyContent: "space-between", // Space between image and form
      alignItems: "flex-start", // Align image and form at the top
    },
    modalImage: {
      width: "45%", // Adjust image width to 45% so there is space for the form
      height: "auto",
      objectFit: "contain",
      borderRadius: "8px",
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "transparent",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
      color: "#000",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "45%", // Adjust form width to 45% to fit alongside the image
    },
    input: {
      padding: "10px",
      fontSize: "14px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      padding: "10px",
      fontSize: "16px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };
  

  return (
    <div>
      {/* Adjust the container class to span the full width */}
      <div className="container-fluid">
        <div className="row">
          {/* Adjusting columns for larger images */}
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg1, "Co-working space")}
          >
            <img src={galleryImg1} alt="Co-working space" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg2, "Virtual Office")}
          >
            <img src={galleryImg2} alt="Virtual Office" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg3, "Dedicated Desk")}
          >
            <img src={galleryImg3} alt="Dedicated Desk" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg4, "Private Office")}
          >
            <img src={galleryImg4} alt="Private Office" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg5, "Meeting Spaces")}
          >
            <img src={galleryImg5} alt="Meeting Spaces" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg6, "Co-working space")}
          >
            <img src={galleryImg6} alt="Co-working space" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg7, "Virtual Office")}
          >
            <img src={galleryImg7} alt="Virtual Office" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg8, "Dedicated Desk")}
          >
            <img src={galleryImg8} alt="Dedicated Desk" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg9, "Private Office")}
          >
            <img src={galleryImg9} alt="Private Office" style={styles.image} />
          </div>
          <div
            className="col-md-3 col-sm-6 col-12"
            style={styles.imageContainer}
            onClick={() => openModal(galleryImg10, "Meeting Spaces")}
          >
            <img src={galleryImg10} alt="Meeting Spaces" style={styles.image} />
          </div>
        </div>
      </div>

      {/* Modal */}
      {isOpen && (
        <div style={styles.modalOverlay} onClick={closeModal}>
          <div
            style={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button style={styles.closeButton} onClick={closeModal}>
              &times;
            </button>
            {currentImage && (
              <>
                <img
                  src={currentImage.imgSrc}
                  alt={currentImage.alt}
                  style={styles.modalImage}
                />
                <form style={styles.form} onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleFormChange}
                    style={styles.input}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleFormChange}
                    style={styles.input}
                  />
                  <textarea
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleFormChange}
                    rows="4"
                    style={styles.input}
                  />
                  <button type="submit" style={styles.button}>
                    Submit
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
